import React from 'react';
import { withRouter } from 'react-router-dom';
import ShowMoreText from 'react-show-more-text';
import ChatBoxComponent from './ChatBoxComponent';
import { appointmentDetails } from '../utils/api';
import Loader from './loader';
import SidebarComponent from './SidebarComponent';
import LeftArrow from '../assets/images/appointment-left-arrow.png';
import moment from 'moment';


const AppointmentDetail = (props) => {   
    const [appointmentDetailsData, setAppointmentDetailsData] = React.useState(null);
    const [appointmentId, setAppointmentId] = React.useState(props.match.params.id);
    const appointment_id = props.match.params.id

    React.useEffect(() => {
        const callAppointmentDetailsApi = () => {
            appointmentDetails(appointment_id, (res)=>{
                if(res.data.status.result === 1){
                    console.log('appointments-details data')
                    console.log(res.data.data)
                    setAppointmentDetailsData(res.data.data)     
                }else{
                    console.log(res.data.status.message)
                }
            }, (err)=>{console.log(err)})
        }
        
        callAppointmentDetailsApi();

    }, [appointment_id]);

    // const ordinalSuffixOf = (i) => {
    //     var j = i % 10,
    //         k = i % 100;
    //     if (j === 1 && k !== 11) {
    //         return "st";
    //     }
    //     if (j === 2 && k !== 12) {
    //         return "nd";
    //     }
    //     if (j === 3 && k !== 13) {
    //         return "rd";
    //     }
    //     return "th";
    // }

    const executeOnClick = (isExpanded) => {
        console.log(isExpanded);
    }

    return (
        <>
        <SidebarComponent>
        { 
            appointmentDetailsData ? 
        <div className="appointment-details">
            <div className="image">
                <img src={LeftArrow} onClick={()=>props.history.push({pathname:'/appointments', state:{status:appointmentDetailsData.status}})} alt=""  />
            </div>  
            
            <div className="right-container">
                <div className="bar">
                    <div className="text">
                        <div className="date">Appointment on {appointmentDetailsData.date} at {appointmentDetailsData.start_time.slice(0, -3)} <span>({appointmentDetailsData.appointment_id})</span> </div>
                        <div className="reason">
                        <ShowMoreText
                            lines={1}
                            more='read more'
                            less='show less'
                            anchorClass='read-full'
                            onClick={()=>executeOnClick()}
                            expanded={false}
                        >
                            {appointmentDetailsData.title}
                        </ShowMoreText>
                        </div>
                    </div>
                    {
                    appointmentDetailsData.case_pdf ?
                    <div className="veiw-report-btn">
                        <a href={appointmentDetailsData.case_pdf} target="_blank" rel="noopener noreferrer" className="">View Case</a> 
                    </div>
                    : ''
                    }
                </div>
                <div className="content">
                    <div className="patient-details">
                        <h5 className="heading">
                            Patient details
                        </h5>
                        <div className="profile-detail">
                            <img src= {appointmentDetailsData.patient.profile_pic} alt="" />
                            <div className="name-age">
                                <div className="name">{appointmentDetailsData.patient.name}</div>
                                <div className="age">{appointmentDetailsData.patient.age}</div>
                            </div>
                        </div>
                        <div className="counts">Previous Appointments ({appointmentDetailsData.previous_appointments.length-1}) </div>
                        <div className="previous-appointment-list">
                        {appointmentDetailsData.previous_appointments.slice(0).reverse().map((previous_a, i) => (
                            <div key={i} className={previous_a.id === parseInt(appointmentId) ? "appointment-list appointment-list-clicked" : "appointment-list"}>
                                <div className="left-details">
                                    <div className='title'>{previous_a.appointment_str} appointment</div>
                                    <div className='id'>{previous_a.appointment_id}</div>
                                </div>
                                <div className="right-details">
                                    <div className="date">{previous_a.date}</div>
                                    <div><b>{moment(previous_a.start_time, 'hh:mm:ss').format('LT')}</b> (15 MINS)</div>
                                </div>
                                <div className="green-arrow">
                                    <div onClick={()=>setAppointmentId(previous_a.id)}><i className="fa fa-arrow-right"></i></div>
                                </div>
                            </div>
                        ))} 
                        </div>    
                    </div>
                    <ChatBoxComponent 
                        patient = {appointmentDetailsData.patient}
                        documents = {appointmentDetailsData.documents}
                        prescriptions = {appointmentDetailsData.prescriptions}
                        appointment_id = {appointmentId}
                        dialog_id = {appointmentDetailsData.dialog_id}
                        pathname = {props.location.pathname}
                    />
                </div>
            </div>    
        </div>
        : <div className="container center"><Loader /></div>
        }
        </SidebarComponent>  
        </> 
    )
}

export default withRouter(AppointmentDetail)
