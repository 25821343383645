import React, { useRef } from 'react'
import { paymentList } from '../utils/api';
import Loader from './loader';
import moment from 'moment';
import SidebarComponent from './SidebarComponent';

const Payment = () => {
    const [filterModal, setFilterModal] = React.useState(null);
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null);
    const [payments, setPayments] = React.useState([])
    const [totalEarning, setTotalEarning] = React.useState("")
    const [weeklyEarning, setWeeklyEarning] = React.useState(0)
    const [paymentDate, setPaymentDate] = React.useState([])
    const [filteredPaymentDate, setFilteredPaymentDate] = React.useState([])
    const [outsideClickedRef, setOutsideClickedRef] = React.useState(false)
    const [filterDates, setFilterDates] = React.useState({
        from: "",
        to: "",
    })

    React.useEffect(() => {
        const callPaymentList = () => {
            let paymentsDateTemp = []
            let paymentsTemp = []
            let amountsTemp = []
            let weeklyAmountsTemp = []
            paymentList((res) => {
                setLoading(false)
                const today = moment();
                const from_date = today.startOf('week').format("YYYY-MM-DD");;
                console.log("week start", from_date);

                if (res.data.status.result === 1) {
                    paymentsDateTemp = [...new Set(res.data.data.map((p, i) => p.appointment.date))]
                    paymentsDateTemp = paymentsDateTemp.sort()
                    paymentsTemp = res.data.data
                    setFilterDates({
                        from: paymentsDateTemp[0],
                        to: paymentsDateTemp[paymentsDateTemp.length - 1]
                    })
                    setPaymentDate(paymentsDateTemp)
                    setPayments(paymentsTemp)
                    setFilteredPaymentDate(paymentsDateTemp)
                    amountsTemp = res.data.data.map(a => Math.floor(a.amount))
                    weeklyAmountsTemp = res.data.data.filter(a => a.appointment.date >= from_date).map(a => Math.floor(a.amount))

                    setTotalEarning(amountsTemp.reduce((a, b) => a + b, 0))
                    setWeeklyEarning(weeklyAmountsTemp.reduce((a, b) => a + b, 0))
                } else {
                    setError(res.data.status.message)
                }
            })
        }
        setLoading(true)
        callPaymentList()
    }, []);



    const onFilterInputChange = (e, name) => {
        let formData = Object.assign({}, filterDates);
        formData[name] = e.target.value;
        setFilterDates(formData);
    }

    const applyFilter = () => {
        let filteredPaymentsDateTemp = []
        let amountsTemp = []
        filteredPaymentsDateTemp = paymentDate.filter(date => (filterDates.from <= date && date <= filterDates.to));
        setFilteredPaymentDate(filteredPaymentsDateTemp)
        amountsTemp = payments.filter(a => (filterDates.from <= a.appointment.date && a.appointment.date <= filterDates.to)).map(a => Math.floor(a.amount))
        setTotalEarning(amountsTemp.reduce((a, b) => a + b, 0))
    }


    function useOutsideAlerter(ref) {
        React.useEffect(() => {
            function handleClickOutside(event) {
                setOutsideClickedRef(ref.current)
                if (ref.current && !ref.current.contains(event.target)) {
                    setFilterModal(null)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const filterModalRef = useRef(null);
    useOutsideAlerter(filterModalRef);


    return (
        <SidebarComponent>
            {error}
            <div className="payment-section">
                {loading ? <div className="container center" style={{ paddingTop: "100px" }}><Loader /></div> :
                    <>
                        {payments.length > 0 ?
                            <>
                                <div className="filter-heading"><span onClick={() => setFilterModal(outsideClickedRef ? '' : !filterModal)}>Filter</span></div>
                                <div className="container">
                                    <div className="total-earning-section">
                                        <div className="earning-this-week">
                                            <div className="text">Earning this week:</div>
                                            <div className="text-amount">₹ {weeklyEarning}</div>
                                        </div>
                                        <div className="earning-till-date">
                                            <div className="text">Earning till date:</div>
                                            <div className="text-amount">₹ {totalEarning}</div>
                                        </div>
                                        <div className="earning-period">
                                            <div className="text">Earning in period:</div>
                                            <div className="from">
                                                <span>From</span>
                                                <input type="date" className="drop-down"
                                                    onChange={(e) => { onFilterInputChange(e, 'from') }}
                                                    value={filterDates.from}
                                                    min={paymentDate[0]}
                                                    max={paymentDate[paymentDate.length - 1]}
                                                />
                                            </div>
                                            <div className="to">
                                                <span>To</span>
                                                <input type="date" className="drop-down" placeholder="16 April, 2020"
                                                    onChange={(e) => { onFilterInputChange(e, 'to') }}
                                                    value={filterDates.to}
                                                    min={filterDates.from}
                                                    max={paymentDate[paymentDate.length - 1]}
                                                />
                                            </div>
                                            <div className="confirm-btn">
                                                <div className="btn" onClick={() => applyFilter()}>Confirm</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="individual-payment-list">
                                        {
                                            filterModal ?
                                                <div className="filter-modal" ref={filterModalRef}>
                                                    <div className="heading">Show data</div>
                                                    <div className="from">
                                                        From <input type="date" className="drop-down"
                                                            onChange={(e) => { onFilterInputChange(e, 'from') }}
                                                            value={filterDates.from}
                                                            min={paymentDate[0]}
                                                            max={paymentDate[paymentDate.length - 1]}
                                                        />
                                                    </div>
                                                    <div className="to">
                                                        To  <input type="date" className="drop-down" placeholder="16 April, 2020"
                                                            onChange={(e) => { onFilterInputChange(e, 'to') }}
                                                            value={filterDates.to}
                                                            min={filterDates.from}
                                                            max={paymentDate[paymentDate.length - 1]}
                                                        />
                                                    </div>
                                                    <div className="confirm-btn">
                                                        <div className="btn" onClick={() => { applyFilter(); setFilterModal(!filterModal); }}>Confirm</div>
                                                    </div>
                                                </div>
                                                : ""
                                        }

                                        {filteredPaymentDate.map((date, i) => (
                                            <div key={i} className="payment">
                                                <div className="date"> {date}</div>
                                                <div className="details">
                                                    {payments.filter((d) => d.appointment.date === date).map((a, i) => (
                                                        <div key={i}>
                                                            {a.appointment.appointment_id}: Received ₹ {a.amount} from {a.appointment.patient.name}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </>
                            : <div className="container center" style={{ paddingTop: "100px" }}>No payments</div>
                        }
                    </>
                }

            </div>
        </SidebarComponent>
    )
}

export default Payment
