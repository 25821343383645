/* global QB */
import React, { useContext, useRef, useState } from 'react';
import { get_internal_notes, add_internal_note, delete_internal_note, send_prescription, getMedicinesList, medicalExaminationReportList } from '../utils/api';
import { getQBDetails, getToken } from '../utils/auth';
import DeleteIcon from '../assets/images/delete-bucket-icon.png';
import DownloadArrow from '../assets/images/download-arrow.png';
// import { Multiselect } from 'react-widgets';
import Select from 'react-dropdown-select';
import { animateScroll } from "react-scroll";
import moment from 'moment';
import ReactSelect from 'react-select'
import MedicalExaminationQuestion, { medicalExaminationQuestionsData1,medicalExaminationQuestionsData2,medicalExaminationQuestionsData3,medicalExaminationQuestionsData4 } from './MedicalExaminationQuestion';
import { Context, useFormContext } from '../utils/createContext';

const Loader = () => {
    return (
        <div className="ldsring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

const Chats = ({ chatDates, chatHistory, loading, patient_name }) => {
    // const [detailVisible, setDetailVisible] = React.useState(false)
    const [toggler, setToggler] = React.useState(false);
    const [image, setImage] = React.useState("");

    React.useEffect(() => {
        const scrollToBottom = () => {
            animateScroll.scrollToBottom({
                containerId: "chat-list"
            });
            console.log('scrolling')
        }
        scrollToBottom()
    })

    const showImage = (url) => {
        console.log(url);
        setImage(url);
        console.log("URL set");
        setToggler(!toggler);
        console.log("Toggler set");
    }

    const closeModal = (e) => {
        if (e.target !== e.currentTarget) return;
        setToggler(!toggler);
        console.log("Toggler set");
    }

    return (
        <>
        <div className="blur-background"></div>
        <div className="chats" id="chat-list">
            {loading ? <div className="container center"><Loader /></div> :
                chatDates.map((date, i) => (
                    <div key={i}>
                        <div className="chat-date">{date === chatDates[0] ? 'Chat started on' : ''} {date}</div>
                        {chatHistory.filter((d) => d.chat_date === date).map((chat, j) => (
                            <div key={j} className={chat.sender_id.toString() === getQBDetails().qb_id.toString() ? 'received-msg' : 'sent-msg'} >
                                <div>
                                    <span className="sender">{chat.sender_id.toString() === getQBDetails().qb_id.toString() ? 'you' : patient_name}</span>
                                </div>
                                <div>
                                    <span className="msg-time">{chat.sent_time}</span>
                                    {chat.attachments && chat.attachments[0] ?
                                        <span className="msg-file">
                                            {chat.attachments[0].type === 'pdf' ?
                                                <a href={`/view-prescription-report/pdf?url=${chat.attachments[0].id.split('?')[0]}`} target="_blank" rel="noopener noreferrer" >
                                                    <div className="pdf-icon">PDF</div><span>{chat.message}</span>
                                                </a> : (chat.attachments[0].type === 'audio' ?
                                                    <a href={chat.attachments[0].id.split('?')[0]} target="_blank" rel="noopener noreferrer" >
                                                        <span><i className="fa fa-file-audio heartbeat" />Heartbeat</span>
                                                    </a> :
                                                    <img
                                                        src={chat.attachments[0].id.split('?')[0]}
                                                        alt="report"
                                                        style={{ width: "100%" }}
                                                        onClick={() => showImage(chat.attachments[0].id.split('?')[0])}
                                                    />)
                                            }
                                        </span> :
                                        <span className="msg-text">{chat.message}</span>}
                                </div>
                            </div>
                        ))}
                    </div>
                ))
            }

            {/* <FsLightbox
                toggler={toggler}
                sources={[
                    image
                ]}
                type="image"
            /> */}
            {
                toggler ?
                    <>
                        <div className="modal-wrapper" onClick={closeModal}>
                            <img src={image} style={{
                                width: "auto",
                                height: "60%",
                                top: "25%",
                                left: "25%",
                            }} alt="preview" />
                        </div>
                    </> : ""
            }
        </div>
        </>
    )
}

const Documents = (props) => {
    let prescriptionTemp = []
    props.docPrescription.map(d=>prescriptionTemp.push({pdf:d.pdf, readable_time:d.readable_time, doc_type:'prescription', id:d.id }))
    let allDocuments = prescriptionTemp.concat(props.documents).sort((a,b)=> moment(new Date(a.readable_time)) - moment(new Date(b.readable_time)))
    let documentsDate = [...new Set(allDocuments.map(p_date => moment(new Date(p_date.readable_time)).format('MMM YYYY')))]
    return (
        <div className="documents" >
            {documentsDate.length > 0 ?
                <>
                    {documentsDate.reverse().map((p_date, i) => (
                        <div key={i} className="montly-docs">
                            <div className="month">
                                {p_date}
                            </div>
                            <div className="docs">
                                {allDocuments.slice(0).reverse().filter(d => moment(new Date(d.readable_time)).format('MMM YYYY') === p_date).map((doc, i) => (
                                    <div key={i} className="doc">
                                        <div className="card">
                                            <a href={`/view-prescription-report/pdf?url=${doc.pdf.split("?")[0]}`} rel="noopener noreferrer" target="_blank" className="">
                                                <div className="doc-time">{moment(new Date(doc.readable_time)).format('LT')}</div>
                                                <div className="doc-date">{moment(new Date(doc.readable_time)).format('Do MMM YYYY')}</div>
                                            </a>
                                        </div>
                                        <div className="doc-name">
                                            {moment(new Date(doc.readable_time)).format('MMM_Do')}_{doc.doc_type}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </>
                : <div className="container center">No Documents</div>}
        </div>
    )
}
const MedicalExaminationReport = (props) => {
    // console.log("MER report",props.merReportList)
    return (
        <div className="documents medical-examination-report-container " >
              <div  className="medical-examination-report">
              <div className="add-medical-examination" onClick={() => props.setMedicalExaminationModal(true)}>
                            <span>+</span> Medical Examination
                    </div>         
            </div>
            {props.merLoading && <div className="container center">Loading Reports...</div>}
            {props.merReportList.length > 0 ?
                <>
                    <div className="montly-docs ">
                        {/* <div className="month">
                            July 10
                        </div> */}
                        <div className="docs">
                            {
                               props.merReportList?.map((report, index) => (
                                    <div className="doc" key={report.id}>
                                        <div className="card">
                                            <a href={`/view-prescription-report/pdf?url=${report?.pdf.split("?")[0]}`} rel="noopener noreferrer" target="_blank" className="">
                                                <div className="doc-time">{moment(new Date(report?.created_at)).format('LT')}</div>
                                                <div className="doc-date">{moment(new Date(report?.created_at)).format('Do MMM YYYY')}</div>
                                            </a>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </div>



                </>
                : <div className="container center">No Documents</div>}
        </div>
    )
}


const InternalNotes = (props) => {
    const callDeleteInternalNoteApi = (note_id) => {
        delete_internal_note(note_id, (res) => {
            if (res.data.status.result === 1) {
                props.callInternalNoteListApi();
                props.setNoteLoading(true)
            } else {
                props.setError(res.data.status.message)
            }
        }, (err) => { props.setError(false) })
    }


    return (
        <>
            {props.noteLoading ? <div className="container center"><Loader /></div>
                :
                <>
                    {props.internalNotesData.length > 0 ?
                        <div className="internal-notes" >
                            {props.internalNotesData.map((note, i) => (
                                <div key={i} className="note-card">
                                    <div className="note-date">
                                        <div className="note-create-date">Added {note.ist_date.slice(0, 10)} {note.ist_date.slice(11, 16)}</div>
                                        <a className="download-arrow" href={note.pdf_url} target="_blank" rel="noopener noreferrer">
                                            <img src={DownloadArrow} alt="" />
                                        </a>
                                        <img
                                            className="delete-icon"
                                            onClick={() => {
                                                callDeleteInternalNoteApi(note.id);
                                            }}
                                            src={DeleteIcon} alt=""
                                        />
                                    </div>
                                    <div className="notes">
                                        <div>{note.note}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        : <div className="container center">No Internal Notes</div>}
                </>
            }
        </>
    )
}


const AddInternalNotesModal = (props) => {
    const [newNote, setNewNote] = React.useState("");

    const onNoteChange = (e) => {
        setNewNote(e.target.value);
    }

    const callAddInternalNoteApi = () => {
        add_internal_note(props.appointment_id, newNote, (res) => {
            if (res.data.status.result === 1) {
                props.callInternalNoteListApi();
                props.setNoteLoading(true)
            } else {
                props.setError(res.data.status.message)
            }
        }, (err) => { props.setError(false) })
    }

    return (
        <div className="add-notes-modal" >
            <textarea
                onChange={onNoteChange}
                value={newNote}
            />
            <button className="add-btn"
                onClick={() => {
                    props.setAddInternalNoteModal(!props.addInternalNoteModal);
                    callAddInternalNoteApi();
                    props.setBoxContent('internal_notes')
                }}
            >
                Add
            </button>
        </div>
    )
}

const AddMedicalExaminationModal = (props) => {
    const [currentPart, setCurrentPart] = useState(1);
    const {state, setAnswer, clearForm } = useFormContext();
    const [loader, setLoader] = useState(false);
    // console.log("formData", state);
    const modalContentRef = useRef(null);
    const handleChange = (questionId, answer) => {
        setAnswer(questionId, answer);
    };
    const handleScrollToTop = () => {
        if (modalContentRef.current) {
            modalContentRef.current.scrollTop = 0;
        }
    };
    const handleNext = () => {
        if (currentPart < 2) {
            setCurrentPart(prev => prev + 1);
              handleScrollToTop();
        } else {
            handleSubmit();
        }
    };

    const handlePrevious = () => {
        if (currentPart > 1) {
            setCurrentPart(prev => prev - 1);
              handleScrollToTop();
        }
    };


    const FormLoader = () => {
        return (
            <div className="loading-div">
                <div></div>
            </div>
        );
    };
    const handleSubmit = async () => {
        // Function to filter out questions with only 'yes' or 'no' answers
        const filterAnswers = (answers) => {
            return answers.filter(answer => ['YES', 'NO'].includes(answer));
        };
        const nestedAnswers = [medicalExaminationQuestionsData1, medicalExaminationQuestionsData2].map((partData, partIndex) => {
            return {
                title: `Part ${partIndex + 1}`,
                questions: partData.map(question => {
                    let subQuestions = [];

                    if (question.subQuestions) {
                        subQuestions = question.subQuestions.map(subQuestion => ({
                            id: subQuestion.id,
                            question: subQuestion.text,
                            answer: filterAnswers([state.answers[question.id]?.[subQuestion.id]]).join('')
                        })).filter(sub => ['YES', 'NO'].includes(sub.answer)); // Only include subQuestions with 'YES' or 'NO'
                    }

                    // Filter the main question's answer
                    const mainAnswer = filterAnswers([state.answers[question.id]]).join('');

                    return {
                        id: question.id,
                        question: question.text,
                        answer: mainAnswer,
                        subQuestions: subQuestions.length > 0 ? subQuestions : [] // Only include subQuestions if they exist
                    };
                }).filter(q => ['YES', 'NO'].includes(q.answer) || (q.subQuestions && q.subQuestions.length > 0)) // Only include questions with 'yes' or 'no' answers or with relevant subQuestions
            };
        });

        // Remove parts where no questions are included
        const formattedData = {
            appointment_id: props.appointment_id,
            medical_examination: nestedAnswers.filter(part => part.questions.length > 0)
        };

        try {
            setLoader(true)
            const url = "https://stgdocpanelapi.yolohealth.co.in/api/v1/doctors/send_medical_examinations/"
            const token = getToken();
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify(formattedData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
                setLoader(false)
            }

            const data = await response.json();

            console.log('MER---- RES data', data)
            console.log(data.status.message)
            if (data.status.result === 1) {

                console.log(data.data)
                props.setMedicalExaminationModal(false);
                props.callMedicalExaminationReportListApi()
                clearForm();
            } else {
                console.log(data.status.message)
                props.setError(data.status.message)

            }
        } catch (error) {
            props.setError(error)
        } finally {
            setLoader(false)
            props.setMerLoading(false);
        }

    };
    const currentData = [medicalExaminationQuestionsData1, medicalExaminationQuestionsData2][currentPart - 1];
  
    return (
        <div className='medical-examination-modal-container'>
               {loader &&  <div className='form-loader'>
                        <FormLoader/>
                </div>}
            <div className="add-medical-examination-modal" ref={modalContentRef}>
                <h3 className='medical-report-heading'>Medical Examination Report - Part {currentPart}</h3>
               
                <form>
                    {currentData?.map((question) => (
                        <MedicalExaminationQuestion
                            key={question.id}
                            question={question}
                            handleChange={handleChange}
                            value={state.answers[question.id] || ''}
                        />
                    ))}
                    <div className="form-navigation">
                        {currentPart !== 1 && (
                            <button
                                type="button"
                                className='previous-report-btn'
                                onClick={handlePrevious}
                                disabled={loader ? true : false}
                            >
                                Previous
                            </button>
                        )}

                        <button
                            type="button"
                            className='submit-report-btn'
                            onClick={handleNext}
                            disabled={loader ? true : false}
                        >
                            {currentPart < 2 ? 'Next' : 'Submit'} 
                        </button>
                     
                    </div>
                      
                </form>
            </div>
        </div>
    );
};





const PrescriptionModal = (props) => {
    const [medicineList,setMedicineList] =  React.useState([])
    const [showError,setShowError] =  React.useState("")
    const allergyOptions = [
        {"label" : "Peanut", "value" : "Peanut"},
        {"label" : "Seafood", "value" : "Seafood"},
        {"label" : "Strawberry", "value" : "Strawberry"}
    ]
    const labTestOptions = [
        {"label" : "Blood", "value" : "Blood"},
        {"label" : "Covid", "value" : "Covid"},
        {"label" : "Thyroid", "value" : "Thyroid"}
    ]


    const [error, setError] = React.useState(null)
    const [labTests, setLabTests] = React.useState([])
    const [allergies, setAllergies] = React.useState([])
    const [comments, setComments] = React.useState("")
    const [loading, setLoading] = React.useState(false);
    const [followUp, setFollowUp] = React.useState('')
    const [newPrescriptions, setNewPrescriptions] = React.useState([
        { number: 1, name: '', dosage: '1-0-0', duration: '', instructions: '' }
    ])

    const handleClose = () => {
        props.hideModal();
    }
    function capitalizeFirstCharacter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    React.useEffect(() => {
        const callMedicineListApi = () => {
            // setLoading(true)
            getMedicinesList((res)=>{
                if(res.data.status.result === 1){
                    
                    let formatData = res?.data?.data?.medicines?.map(item => ({label: capitalizeFirstCharacter(item.name), value: capitalizeFirstCharacter(item.name)}));
                    setMedicineList(formatData)
                }else{
                    setError(res.data.status.message)
                }
            }, (err)=>{setError(false)})
        }
        callMedicineListApi();
    }, []);


    const callPrescriptionApi = () => {
        console.log('all selected allergies')
        console.log(allergies)
        let submitForm = true
        
          // Check for empty name field in newPrescriptions
        for (const prescription of newPrescriptions) {
            if (prescription.name === "") {
                setShowError("Please add medicine");
                setTimeout(() => {
                    setShowError(null);
                }, 3000);
                return;
            }
        }
        var data =
        {
            appointment_id: props.appointment_id,
            medicines: newPrescriptions,
            "labs": labTests.map(i => i.value).join(", "),
            "allergies": allergies.map(i => i.value).join(", "),
            comments: comments,
            "follow-up": followUp
        };

        newPrescriptions.forEach((element) => {
            if (!element.dosage) {
                submitForm = null
            }
        })

        console.log('data to be sent')
        console.log(data)
        if (data.medicines.length > 0) {
            if (!submitForm) {
                setLoading(false);
                setError("please fill dosage!");
            } else {
                console.log(submitForm)
                setLoading(true);
                setError(null);
                send_prescription(data, (res) => {
                    console.log(res.data.data);
                    setLoading(false);
                    props.sendPrescriptionChat(res.data.data.pdf);
                    let prescriptionDocTemp = { id: res.data.data.id, pdf: res.data.data.pdf, readable_time: res.data.data.created_date }
                    props.setDocPrescription([...props.docPrescription, prescriptionDocTemp])
                    props.hideModal();
                    // props.setPrescriptions(!props.prescriptions)
                })
            }

        } else {
            setError('Please add medicine')
        }

    }

    const addPrescription = () => {
        let prescriptionTemp = { number: 1, name: '', dosage: '1-0-0', duration: '', instructions: '' }
        setNewPrescriptions([...newPrescriptions, prescriptionTemp])
    }

    const removePrescription = (idx) => {
        newPrescriptions.splice(idx, 1)
        setNewPrescriptions([...newPrescriptions])
    }

    const onChangeMedicineDetails = i => e => {
        let prescriptionTemp = [...newPrescriptions];
        prescriptionTemp[i][e.target.name] = e.target.value;
        setNewPrescriptions(prescriptionTemp);
    }

    const onChangeMedicine = (i, e) => {
        let prescriptionTemp = [...newPrescriptions];
        prescriptionTemp[i]['name'] = e ? e.value : '';
        setNewPrescriptions(prescriptionTemp);
    }

    const resetPrescriptionData = () => {
        setLabTests([])
        setAllergies([])
        setComments("")
        setLoading(false)
        setFollowUp('')
        newPrescriptions.splice(0, newPrescriptions.length)
        // setNewPrescriptions([...newPrescriptions, {number : 1, name:'Vicodin',dosage:'', duration:'', instructions:'' }])
    }

    const closeErrorBox = () => {
        setError(null);
    }

    return (
        <div className="prescription-modal" >
            <div className="info">
                <div className="text">
                    Prescription # <span>Yolo-12-61</span>
                </div>
                <div className="profile-detail">
                    <img src={props.patient.profile_pic} alt="" />
                    <div className="name-age">
                        <div className="name">{props.patient.name}</div>
                        <div className="age">{props.patient.age}</div>
                    </div>
                </div>
            </div>
            <div className="prescription-details">
                <div className="prescriptions-heading">
                    <div className="serial-number">S.No.</div>
                    <div className="medicines">Medicine</div>
                    <div className="dosage">Dosage</div>
                    <div className="duration">Duration</div>
                    <div className="instructions">instructions</div>
                </div>
                {newPrescriptions.map((med, i) => (
                    <div key={i} className="medicine-details">
                        <div className="medicines">
                            <div className="serial-number">{i + 1}</div>
                            <ReactSelect autoFocus={false} 
                             noOptionsMessage={({ inputValue }) => `No result found for "${inputValue}"`}
                             placeholder="Select Medicine" options={medicineList}   onChange={(selectedOption) => onChangeMedicine(i, selectedOption)}  
                                 styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: "none",
                                    outline:"none",
                                    width: "100%", border: 0, backgroundColor: "transparent"
                                }),
                            }} />
                            <select className="dosage" name="dosage" id="" onChange={onChangeMedicineDetails(i)}>
                                <option value="1-0-0">1-0-0</option>
                                <option value="0-1-0">0-1-0</option>
                                <option value="0-0-1">0-0-1</option>
                                <option value="1-1-0">1-1-0</option>
                                <option value="1-0-1">1-0-1</option>
                                <option value="0-1-1">0-1-1</option>
                                <option value="1-1-1">1-1-1</option>
                                <option value="1-1-1-1">1-1-1-1</option>
                            </select>
                            {/* <input placeholder='0-1-0' className="dosage" name="dosage" onChange={onChangeMedicineDetails(i)} /> */}
                            <input placeholder='5 days' className="duration" name="duration" onChange={onChangeMedicineDetails(i)} />
                            <input placeholder='Write instructions' className="instructions" name="instructions" onChange={onChangeMedicineDetails(i)} />
                        </div>
                        <div className="remove-btn" onClick={() => removePrescription(i)} >
                            <span>×</span>
                        </div>
                    </div>
                ))}
                 {showError && <p style={{color:'red',marginTop:10,fontSize:"12px",width:"100%",textAlign:"center"}}>{showError}</p>}
                <div className="add-btn" ><span onClick={() => addPrescription()}>+</span></div>
            </div>
            <div className="allergies content">
                <div className="text">Allergies</div>
                <Select
                    multi
                    create
                    options={allergyOptions}
                    onChange={(values) => setAllergies(values)}
                    clearOnSelect
                    keepSelectedInList={false}
                    color={'#2AA1DA'}
                />
            </div>
            <div className="comments content">
                <div className="text">Comments</div>
                <textarea value={comments} onChange={(e) => setComments(e.target.value)} />
            </div>
            <div className="follow-up-date content">
                <div className="text">Follow up Date</div>
                <input className="inputs" type="date" value={followUp} onChange={(e) => setFollowUp(e.target.value)} />
            </div>
            <div className="lab-test content">
                <div className="text">Lab test</div>
                <Select
                    multi
                    create
                    options={labTestOptions}
                    onChange={(values) => setLabTests(values)}
                    clearOnSelect
                    keepSelectedInList={false}
                    color={'#2AA1DA'}
                />
            </div>
            <div className="buttons">
                <div className="reset-btn" onClick={handleClose}>Close</div>
                <div className="reset-btn" onClick={() => resetPrescriptionData()}>Reset</div>
                <div className="confirm-btn" onClick={callPrescriptionApi} >
                    {loading ? <Loader /> : "Confirm"}
                </div>
            </div>
            {
                error ?
                    <div className="errors">
                        <span>{error}</span> <span onClick={closeErrorBox} className="remove-error">×</span>
                    </div>
                    : ""
            }
        </div>
    )
}


const ChatBoxComponent = (props) => {
    const [error, setError] = React.useState(null);
    const [noteLoading, setNoteLoading] = React.useState(false);
    const [merLoading, setMerLoading] = React.useState(false);
    const [internalNotesData, setInternalNotesData] = React.useState([]);
    const [addInternalNoteModal, setAddInternalNoteModal] = React.useState(null);
    const [medicalExaminationModal, setMedicalExaminationModal] = React.useState(null);
    const [prescriptions, setPrescriptions] = React.useState(null);
    const [chatHistory, setChatHistory] = React.useState([]);
    const [chatDates, setChatDates] = React.useState([]);
    const [qb, setQb] = React.useState(null);
    const [boxContent, setBoxContent] = React.useState('chat');
    const [initLoading, setInitLoading] = React.useState(false);
    const [docPrescription, setDocPrescription] = React.useState(props.prescriptions);
    const [merReportList,setMerReportList] = useState([]);
    const { clearForm } = useFormContext();
    const [enterMessage, setEnterMessage] = React.useState({
        message: "",
        sender_id: getQBDetails().qb_id,
        chat_date: "",
        sent_time: ""
    });
    const appointment_id = props.appointment_id;

    React.useEffect(() => {
        const onMessage = (userId, message) => {
            if (message && userId === props.patient.qb_id) {
                var received_time = moment();
                let receivedMsg = {}
                receivedMsg['message'] = message.body;
                receivedMsg['sender_id'] = props.patient.qb_id;
                receivedMsg['chat_date'] = received_time.format("MMM Do YYYY");
                receivedMsg['sent_time'] = received_time.format("HH:mm");
                receivedMsg['attachments'] = message.extension.attachments;
                let chatDateTemp = []
                chatDateTemp = [received_time.format("MMM Do YYYY")]
                setChatDates(chatDateTemp)
                setChatHistory([...chatHistory, receivedMsg])

            }
        }


        if (qb) {
            qb.chat.onMessageListener = onMessage;
        }

    }, [qb, chatHistory, props.patient.qb_id])

    React.useEffect(() => {
        callMedicalExaminationReportListApi();
    }, []);

    const callMedicalExaminationReportListApi = async() => {
        setMerLoading(true)
        try {
                const res = await fetch(`https://stgdocpanelapi.yolohealth.co.in/api/v1/doctors/medical_examinations/${props.appointment_id}`)
                const data = await res.json();
                if(data.status.result === 1){
                    setMerReportList(data.data)     
                }else{
                    console.log(data.status.message)
                }
        } catch (error) {
            setMerLoading(false)
        }finally{
            setMerLoading(false)
        }
    }

    const callInternalNoteListApi = () => {
        get_internal_notes(appointment_id, (res) => {
            setNoteLoading(false)
            if (res.data.status.result === 1) {
                console.log(res.data.data)
                setInternalNotesData(res.data.data)
            } else {
                setInternalNotesData([])
                setError(res.data.status.message)
            }
        }, (err) => { setError(false) })
    }

    React.useEffect(() => {
        get_internal_notes(appointment_id, (res) => {
            if (res.data.status.result === 1) {
                setInternalNotesData(res.data.data)
            } else {
                setError(res.data.status.message)
            }
        }, (err) => { setError(false) })

        if (props.pathname.endsWith('notes/')) {
            setBoxContent('internal_notes')
        }


        const quickbloxApis = () => {

            setInitLoading(true);

            var params = { login: getQBDetails().qb_username, password: getQBDetails().qb_password };
            var CREDENTIALS = {
                appId: '80293',
                authKey: 'tPWasabEppO8jpT',
                authSecret: 'xVHuJFqghs3ESsB'
            };
            QB.init(CREDENTIALS.appId, CREDENTIALS.authKey, CREDENTIALS.authSecret);

            setQb(QB);

            QB.createSession(params, function (err, result) {
                if (result) {
                    QB.login(params, function (err, result) {
                        if (result) {
                            var userCredentials = {
                                userId: getQBDetails().qb_id,
                                password: getQBDetails().qb_password
                            };

                            QB.chat.connect(userCredentials, function (error, contactList) {

                                var params = {
                                    sort_desc: 'date_sent',
                                    limit: 100,
                                    skip: 0,
                                    chat_dialog_id: props.dialog_id,
                                };
                                QB.chat.message.list(params, function (error, messages) {
                                    let chatDateTemp = []
                                    setInitLoading(false);
                                    chatDateTemp = [...new Set(messages.items.reverse().map((data, i) => moment(data.created_at).format("MMM Do YYYY")))]
                                    setChatDates(chatDateTemp)
                                    setChatHistory(messages.items.map((msg, i) => ({ message: msg.message, attachments: msg.attachments, sender_id: msg.sender_id, chat_date: moment(msg.created_at).format("MMM Do YYYY"), sent_time: moment(msg.created_at).format("HH:mm") })))
                                });
                            });
                        }
                    });
                }
            });
        }
        quickbloxApis();


    }, [appointment_id, props.pathname, props.dialog_id]);

    const dialogId = props.dialog_id;
    const sendMessage = (body) => {
        var message = {
            type: 'chat',
            body: body,
            extension: {
                save_to_history: 1,
                dialog_id: dialogId
            },
            markable: 1
        };

        var opponentId = props.patient.qb_id;
        message.id = qb.chat.send(opponentId, message);
    }
    const sendMessageAttachment = (url) => {
        var message = {
            type: 'chat',
            body: "Prescription",
            extension: {
                save_to_history: 1,
                dialog_id: dialogId,
                attachments: [{ id: url, type: "pdf" }]
            },
            markable: 1
        };

        var opponentId = props.patient.qb_id;
        var new_date = new Date(Date.now()).toLocaleString('en-US', { hour12: false });
        const enter_time = moment(new_date);

        console.log("inside send message", qb.chat);
        message.id = qb.chat.send(opponentId, message);
        message.sender_id = getQBDetails().qb_id
        message.message = "Prescription"
        message.attachments = [{ id: url, type: "pdf" }]
        message.sent_time = enter_time.format("HH:mm");
        message.chat_date = enter_time.format("MMM Do YYYY");
        if (!(chatDates.indexOf(message.chat_date) > -1)) {
            // if the doctor send message today as the first message of the day, so chatDates arr doesn't have today's date thats why inserting the date
            setChatDates([...chatDates, message.chat_date])
        }
        setChatHistory([...chatHistory, message])
    }

    const onTypeMessage = (e, name) => {
        let formData = Object.assign({}, enterMessage);
        formData[name] = e.target.value;
        setEnterMessage(formData);
    }

    const onEnterMessage = (event) => {
        var new_date = new Date(Date.now()).toLocaleString('en-US', { hour12: false });
        const enter_time = moment(new_date);
        let formData = Object.assign({}, enterMessage);
        formData["chat_date"] = enter_time.format("MMM Do YYYY");
        formData["sent_time"] = enter_time.format("HH:mm");
        setEnterMessage(formData);

        if (event.key === 'Enter') {
            if (/^\s*$/.test(enterMessage.message)) {
                setEnterMessage({
                    message: "",
                    sender_id: getQBDetails().qb_id,
                    chat_date: "",
                    sent_time: ""
                })
            } else {
                if (!(chatDates.indexOf(enterMessage.chat_date) > -1)) {
                    // if the doctor send message today as the first message of the day, so chatDates arr doesn't have today's date thats why inserting the date
                    setChatDates([...chatDates, enterMessage.chat_date])
                }
                setChatHistory([...chatHistory, enterMessage])
                sendMessage(enterMessage.message)
                setEnterMessage({
                    message: "",
                    sender_id: getQBDetails().qb_id,
                    chat_date: "",
                    sent_time: ""
                })
                event.preventDefault();
            }
        }
    }

    const handleModalWrapperClick = (e, callback) => {
        if (e.target !== e.currentTarget) return;
        callback()
    }

    return (
        <>
            <div className="chatbox-container">
                <div className="container-bar">
                    <div className={boxContent === 'chat' ? 'active' : ''} onClick={() => setBoxContent('chat')}>Chat</div>
                    <div className={boxContent === 'documents' ? 'active' : ''} onClick={() => setBoxContent('documents')}>Documents</div>
                    <div className={boxContent === 'internal_notes' ? 'active' : ''} onClick={() => { setBoxContent('internal_notes') }}>Internal notes</div>
                    <div className={boxContent === 'medical_examination_report' ? 'active' : ''} onClick={() => { setBoxContent('medical_examination_report') }}>Medical Examination Report</div>
                </div>
                <div className="chat-box-wrapper">
                    {
                        boxContent === 'chat' ?
                            <Chats
                                chatDates={chatDates}
                                chatHistory={chatHistory}
                                loading={initLoading}
                                patient_name={props.patient.name}
                            />
                            : ""
                    }
                    {
                        boxContent === 'documents' ?
                            <Documents patient_name={props.patient.name} docPrescription={docPrescription} documents={props.documents}/>
                            : ""
                    }
                    {
                        boxContent === 'medical_examination_report' ?
                            <MedicalExaminationReport setMerLoading={setMerLoading} merLoading={merLoading} setMerReportList={setMerReportList} merReportList={merReportList}  appointment_id={appointment_id} setMedicalExaminationModal={setMedicalExaminationModal}  />
                            : ""
                    }
                    {
                        boxContent === 'internal_notes' ?
                            <InternalNotes
                                internalNotesData={internalNotesData}
                                callInternalNoteListApi={callInternalNoteListApi}
                                setError={setError}
                                error={error}
                                setNoteLoading={setNoteLoading}
                                noteLoading={noteLoading}
                            />
                            : ""
                    }
                    <div className="type-msg-box">
                        <div className="msg-input">
                            <span className="circle">
                            </span>
                            <textarea
                                className="text-area"
                                onKeyPress={(event) => onEnterMessage(event)}
                                onChange={(e) => { onTypeMessage(e, 'message') }}
                                value={enterMessage.message}
                                placeholder="Type a message"
                            />
                        </div>
                        <div className="prescription" onClick={() => setPrescriptions(!prescriptions)}>
                            <span>+</span> Prescription
                    </div>
                        <div className="internal-note" onClick={() => setAddInternalNoteModal(!addInternalNoteModal)}>
                            <span>+</span> Internal Note
                    </div>
                    </div>
                </div>
            </div>
            {
                addInternalNoteModal ?
                    <>
                        <div className="modal-wrapper" onClick={(e) => handleModalWrapperClick(e, () => setAddInternalNoteModal(!addInternalNoteModal))}>
                            <AddInternalNotesModal
                                setAddInternalNoteModal={setAddInternalNoteModal}
                                addInternalNoteModal={addInternalNoteModal}
                                appointment_id={appointment_id}
                                callInternalNoteListApi={callInternalNoteListApi}
                                setError={setError}
                                setBoxContent={setBoxContent}
                                setNoteLoading={setNoteLoading}
                            />
                        </div>

                    </>
                    : ""
            }
            {
                medicalExaminationModal ?
                    <>
                        <div className="modal-wrapper" onClick={(e) => handleModalWrapperClick(e, () => {
                            setMedicalExaminationModal(!medicalExaminationModal)
                            clearForm();
                            })}>
                            <AddMedicalExaminationModal
                                setMedicalExaminationModal={setMedicalExaminationModal}
                                medicalExaminationModal={medicalExaminationModal}
                                appointment_id={appointment_id}
                                callMedicalExaminationReportListApi={callMedicalExaminationReportListApi}
                                // callCreateMedicalExaminationReport={callCreateMedicalExaminationReport}
                                setError={setError}
                                setBoxContent={setBoxContent}
                                setMerLoading={setMerLoading}
                            />
                        </div>

                    </>
                    : ""
            }

            {
                prescriptions ?
                    <>
                        <div className="modal-wrapper" onClick={(e) => handleModalWrapperClick(e, () => setPrescriptions(!prescriptions))}>
                            <PrescriptionModal
                                setPrescriptions={setPrescriptions}
                                prescriptions={prescriptions}
                                hideModal={() => { setPrescriptions(false) }}
                                sendPrescriptionChat={(f) => { sendMessageAttachment(f) }}
                                patient={props.patient}
                                docPrescription={docPrescription}
                                setDocPrescription={setDocPrescription}
                                appointment_id={appointment_id}
                            />
                        </div>

                    </>
                    : ""
            }
        </>
    )
}

export default ChatBoxComponent
