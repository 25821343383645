
import axios from 'axios';
import { getToken } from './auth';


const PRE_BASE_URL = 'https://stgdocpanelapi.yolohealth.co.in/api/v1/'
const BASE_URL = PRE_BASE_URL + 'doctors/'
// const BASE_URL = 'http://localhost:8080/api/v1/doctors/'

const URLS = {
  login_with_code: BASE_URL + 'login_with_code/',
  medicine_list: 'https://stgapi.yolohealth.co.in/api/v1/doctors/medicine-list',
  doctor_profile_details: BASE_URL + 'doctor_detail/',
  update_doctor_profile: BASE_URL + 'update_doctor_profile/',
  appointment_list: BASE_URL + 'appointments/',
  payment_list: BASE_URL + 'payment_list/',
  set_doctor_status: BASE_URL + 'set_doctor_status/',
  dashboard: BASE_URL + 'dashboard/',
  notifications: BASE_URL + 'notifications/',
  general_clinic_timings: BASE_URL + 'clinic_timings/',
  daily_clinic_timings: BASE_URL + 'daily_clinic_timings/',
  appointments_today: BASE_URL + 'appointments_today/',
  calendar: BASE_URL + 'calendar/',
  qualifications: BASE_URL + 'qualifications/',
  specializations: BASE_URL + 'specializations/',
  internal_note_list: BASE_URL + 'internal_note_list/',
  add_internal_note: BASE_URL + 'add_note/',
  delete_internal_note: BASE_URL + 'delete_note/',
  navbar: BASE_URL + 'navbar/',
  patient_by_qb: BASE_URL + 'patient_by_qb/',
  send_prescription: BASE_URL + 'send_prescription/',
  call_logs: PRE_BASE_URL + 'call_logs/',
  check_token : BASE_URL + 'check_token/',
}

const makeRequest = (url, method="get", data={}, onSuccess=()=>{}, onError=()=>{}) => {
  console.log(data)
  const headers = {
    Authorization: getToken(),
  }
  const config = {
    method: method,
    url: url,
    headers: headers
  }
  if (method==='get') {
    config.params = data
  }else{
    config.data = data
  }
  
  axios(
    config
  ).then(res=>{
    console.log(res);
    onSuccess(res);
  }).catch(err=>{
    console.log(err);
    onError(err);
    console.log('no internet conn')
  })
}

export const loginWithCode = (loginCode, onSuccess, onError=()=>{}) => {
  makeRequest(URLS.login_with_code, "post", {login_code: loginCode}, onSuccess, onError)
}

export const doctorProfileDetails = (onSuccess, onError=()=>{}) => {
  makeRequest(URLS.doctor_profile_details, "get", {}, onSuccess, onError)
}
export const getMedicinesList = (onSuccess, onError=()=>{}) => {
  makeRequest(URLS.medicine_list, "get", {}, onSuccess, onError)
}

export const updateDoctorProfile = (data, onSuccess, onError=()=>{}) => {
  makeRequest(URLS.update_doctor_profile, "put", data, onSuccess, onError)
}


export const setDoctorStatus = (status, onSuccess, onError=()=>{}) => {
  makeRequest(URLS.set_doctor_status, "put", {status: status}, onSuccess, onError)
}


export const uploadFile = (file) => {
  const url = BASE_URL + 'document_upload/'
  var formData = new FormData();
  formData.append("doc", file);
  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': getToken()
    }
  })
}

export const uploadSignature = (file) => {
  const url = BASE_URL + 'upload_signature/'
  var formData = new FormData();
  formData.append("signature", file);
  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': getToken()
    }
  })
}

export const appointmentList = (onSuccess, onError=()=>{}) => {
  makeRequest(URLS.appointment_list, "get", {}, onSuccess, onError)
}

export const filteredAppointmentList = (filter_by, onSuccess, onError=()=>{}) => {
  console.log('filter_by in api')
  console.log(filter_by)
  makeRequest(URLS.appointment_list, "get", {filter_by:filter_by}, onSuccess, onError)
}

export const paymentList = (onSuccess, onError=()=>{}) => {
  makeRequest(URLS.payment_list, "get", {}, onSuccess, onError)
}

export const appointmentDetails = (id,onSuccess, onError=()=>{}) => {
  makeRequest(URLS.appointment_list+id, "get", {}, onSuccess, onError)
}

export const dashboard = (onSuccess, onError=()=>{}) => {
  makeRequest(URLS.dashboard, "get", {}, onSuccess, onError)
}

export const notifications = (onSuccess, onError=()=>{}) => {
  makeRequest(URLS.notifications, "get", {}, onSuccess, onError)
}

export const mark_seen_all_notifications = (onSuccess, onError=()=>{}) => {
  makeRequest(URLS.notifications, "delete", {}, onSuccess, onError)
}

export const mark_seen_notification = (id, onSuccess, onError=()=>{}) => {
  makeRequest(URLS.notifications + id, "put", {}, onSuccess, onError)
}

export const get_general_timings = (onSuccess, onError=()=>{}) => {
  makeRequest(URLS.general_clinic_timings, "post", {}, onSuccess, onError)
}

export const set_general_timings = (timings, onSuccess, onError=()=>{}) => {
  makeRequest(URLS.general_clinic_timings, "put", {timings: timings}, onSuccess, onError)
}

export const get_daily_timings = (date, onSuccess, onError=()=>{}) => {
  makeRequest(URLS.daily_clinic_timings, "post", {date: date}, onSuccess, onError)
}

export const set_daily_timings = (date, timings, onSuccess, onError=()=>{}) => {
  makeRequest(URLS.daily_clinic_timings, "put", {date: date, timings: timings}, onSuccess, onError)
}

export const appointments_today = (onSuccess, onError=()=>{}) => {
  makeRequest(URLS.appointments_today, "get", {}, onSuccess, onError)
}

export const get_qualifications = (onSuccess, onError=()=>{}) => {
  makeRequest(URLS.qualifications, "get", {}, onSuccess, onError)
}

export const get_specializations = (onSuccess, onError=()=>{}) => {
  makeRequest(URLS.specializations, "get", {}, onSuccess, onError)
}

export const calendar = (from, onSuccess, onError=()=>{}) => {
  makeRequest(URLS.calendar, "post", {from: from}, onSuccess, onError)
}

export const get_internal_notes = (appointment_id, onSuccess, onError=()=>{}) => {
  makeRequest(URLS.internal_note_list, "post", {appointment_id: appointment_id}, onSuccess, onError)
}

export const add_internal_note = (appointment_id, note, onSuccess, onError=()=>{}) => {
  makeRequest(URLS.add_internal_note, "post", {appointment_id: appointment_id, note:note}, onSuccess, onError)
}

export const delete_internal_note = (note_id, onSuccess, onError=()=>{}) => {
  makeRequest(URLS.delete_internal_note, "post", {note_id: note_id}, onSuccess, onError)
}

export const navbar = (onSuccess, onError=()=>{}) => {
  makeRequest(URLS.navbar, "get", {}, onSuccess, onError)
}

export const get_patient_by_qb = (qb_id, onSuccess, onError=()=>{}) => {
  makeRequest(URLS.patient_by_qb, "post", {qb_id: qb_id}, onSuccess, onError)
}

export const send_prescription = (data, onSuccess, onError=()=>{}) => {
  makeRequest(URLS.send_prescription, "post", data, onSuccess, onError)
}

export const create_call_log = (appointment_id, onSuccess, onError=()=>{}) => {
  makeRequest(URLS.call_logs + 'start', "post", {appointment_id: appointment_id}, onSuccess, onError)
}

export const call_heartbeat = (log_id, onSuccess, onError=()=>{}) => {
  console.log('calling heartbeat api')
  makeRequest(URLS.call_logs + 'heartbeat', "post", {log_id: log_id}, onSuccess, onError)
}

export const check_token = (token, onSuccess, onError=()=>{}) => {
  makeRequest(URLS.check_token, "post", {token: token}, onSuccess, onError)
}
