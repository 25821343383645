import React, { useRef } from 'react'
import { withRouter } from 'react-router-dom';
import { doctorProfileDetails, updateDoctorProfile, get_qualifications, get_specializations } from '../utils/api';
import { uploadFile, uploadSignature } from '../utils/api'
import Loader from './loader';
import SidebarComponent from './SidebarComponent';
import YoloInput from '../shared/input/index.js';
import ProfilePic from './edit-profile-pic.js'


const UpdateProfile = (props) => {
    const [error, setError] = React.useState(null);
    const [successMsg, setSuccessMsg] = React.useState(null);
    const [signatureUploadedMsg, setSignatureUploadedMsg] = React.useState(null);
    const [uploadedDocuments, setUploadedDocuments] = React.useState([])
    const [profilePicture, setProfilePicture] = React.useState({})
    const [specializations, setSpecializations] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [qualifications, setQualifications] = React.useState([])
    const [signatureUrl, setSignatureUrl] = React.useState(null);
    const [signatureFile, setSignatureFile] = React.useState(null);
    const fileInput = useRef(null)
    const [profileData, setProfileData] = React.useState({
        first_name: "",
        last_name: "",
        email: "",
        fees: "",
        address: "",
        specialization: "",
        qualification: "",
        documents: [],
        registration_number: "",
        profile_picture: 1,
    })




    React.useEffect(() => {
        const callDoctorProfileDetailsApi = () => {
            let doctorDetails = Object.assign({});
            let documentsTemp = []
            let profilePictureTemp = {}
            doctorProfileDetails((res) => {
                setLoading(false);
                if (res.data.status.result === 1) {
                    doctorDetails.first_name = res.data.data.first_name
                    doctorDetails.last_name = res.data.data.last_name
                    doctorDetails.email = res.data.data.email
                    doctorDetails.specialization = res.data.data.specialization
                    doctorDetails.registration_number = res.data.data.registration_number
                    doctorDetails.fees = res.data.data.fees
                    doctorDetails.address = res.data.data.address
                    doctorDetails.qualification = res.data.data.qualification.map((q, i) => (q))[0]
                    profilePictureTemp = res.data.data.profile_picture
                    if (profilePictureTemp !== null) { doctorDetails.profile_picture = profilePictureTemp.id } else { doctorDetails.profile_picture = null }
                    setProfilePicture(profilePictureTemp)

                    documentsTemp = res.data.data.documents.map((d, i) => ({ doc: d.doc.id, doc_url: d.doc.doc, doc_title: d.doc_title }))
                    doctorDetails.documents = documentsTemp
                    setUploadedDocuments(documentsTemp)
                    setProfileData(doctorDetails)
                    setSignatureUrl({preview : res.data.data.signature_url.split('?')[0], raw:res.data.data.signature_url})
                } else {
                    setError(res.data.status.message)
                }
            })
        }
        setLoading(true);
        callDoctorProfileDetailsApi()

        get_specializations((res) => setSpecializations(res.data.data))
        get_qualifications((res) => setQualifications(res.data.data))

    }, []);

    const callDoctorUpdateProfileDetailsApi = () => {
        setSuccessMsg(null)
        setError(null)
        setLoading(true);
        updateDoctorProfile(profileData, (res) => {
            setLoading(false)
            if (res.data.status.result === 1) {
                setSuccessMsg(res.data.status.message)
            } else {
                setError(res.data.status.message)
            }
        })
    }

    const callDocumentUploadApi = (file, name) => {
        uploadFile(file).then((res) => {
            if (res.data.status.result === 1) {
                let cetificateTemp = {}
                console.log(res.data.data)
                cetificateTemp["doc_id"] = res.data.data.doc_id
                cetificateTemp["doc_url"] = res.data.data.doc_url
                cetificateTemp["doc_title"] = file.name
                uploadedDocuments.push(cetificateTemp)
                setUploadedDocuments([...uploadedDocuments])
                onFileInputChange(uploadedDocuments, name);
            } else {
                setError(res.data.status.message)
            }
        })
    }

    const onProfileInputChange = (e, name) => {
        let formData = Object.assign({}, profileData);
        formData[name] = e.target.value;
        setProfileData(formData);
    }

    const onFileInputChange = (e, name) => {
        let formData = Object.assign({}, profileData);
        formData[name] = e;
        setProfileData(formData);
    }

    const onDocumentUpload = (e, name) => {
        callDocumentUploadApi(e.target.files[0], name)
    }

    const handleClick = () => {
        fileInput.current.click();
    }

    const removeDocument = (idx) => {
        uploadedDocuments.splice(idx, 1)
        setUploadedDocuments([...uploadedDocuments])
        console.log(uploadedDocuments)
    }

    const signatureInput = useRef(null)
    const selectSignatureImg = () => {
        signatureInput.current.click();
    }
    
    const onSelectSignatureImage = (e) => {
        setSignatureFile(e.target.files[0]);
        setSignatureUrl({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0]
        });
    }

    const callSignatureUploadApi = () => {
        uploadSignature(signatureFile).then((res) => {
            if (res.data.status.result === 1) {
                console.log(res.data.data)
                setSignatureFile(null)
                setSignatureUploadedMsg(res.data.status.message)
                setTimeout(() => {
                    setSignatureUploadedMsg(null)
                  }, 3000);
            } else {
                setError(res.data.status.message)
            }
        })
    }

    return (
        <SidebarComponent>
            {loading ? <div className="container center"><Loader /></div> :
                <section className="update-profile-section">
                    <div className="file-inputs">
                        <ProfilePic profilePicture={profilePicture} onFileUpload={(e) => { onFileInputChange(e.doc_id, 'profile_picture') }} />
                        <div className="signature">
                            {signatureUrl ? <img src={signatureUrl.preview} alt="profile" /> : ''}
                            <input
                            type="file"
                            style={{ display: "none" }}
                            onChange={onSelectSignatureImage}
                            ref={signatureInput}
                            accept="image/png"
                            />
                            { signatureFile ?
                                <>
                                <div className="signature-btn" onClick={()=>callSignatureUploadApi()} >Confirm ?</div>
                                {/* <div className="signature-btn" onClick={()=>callSignatureUploadApi()} >Cancel</div> */}
                                </>
                                :
                                <div className="signature-btn" onClick={()=>selectSignatureImg()} >{signatureUrl ? 'Change Signature' : 'Add Signature'}</div>
                            }    
                        </div>
                        {
                            signatureUploadedMsg ?
                                <div className="signature-msg">
                                    <span>{signatureUploadedMsg}</span>
                                </div>
                                : ""
                        }
                    </div>    
                    <div className="profile-details">
                        {
                            error ?
                                <div className="errors">
                                    <span>{error}</span>
                                </div>
                                : ""
                        }
                        {
                            successMsg ?
                                <div className="success-msg">
                                    <span>{successMsg}</span>
                                </div>
                                : ""
                        }
                        <div className="section">
                            <div className="input-field">
                                <div className="label">First Name</div>
                                <YoloInput
                                    className="input-base name-input"
                                    onChange={(e) => { onProfileInputChange(e, 'first_name') }}
                                    value={profileData.first_name}
                                />
                            </div>
                            <div className="input-field">
                                <div className="label">Last Name</div>
                                <YoloInput
                                    className="input-base name-input"
                                    onChange={(e) => { onProfileInputChange(e, 'last_name') }}
                                    value={profileData.last_name}
                                />
                            </div>
                        </div>
                        <div className="section">
                            <div className="input-field">
                                <div className="label">Specialization</div>
                                <YoloInput
                                    onChange={(e) => { console.log(e); onProfileInputChange(e, 'specialization') }}
                                    value={profileData.specialization}
                                    options={specializations}
                                />
                            </div>
                            <div className="input-field">
                                <div className="label">Qualification</div>
                                <YoloInput
                                    onChange={(e) => { onProfileInputChange(e, 'qualification') }}
                                    value={profileData.qualification}
                                    options={qualifications}
                                />
                            </div>
                        </div>
                        <div className="section">
                            <div className="input-field">
                                <div className="label">Email ID</div>
                                <YoloInput
                                    onChange={(e) => { onProfileInputChange(e, 'email') }}
                                    value={profileData.email}
                                />
                            </div>
                            <div className="input-field">
                                <div className="label">Fees</div>
                                <YoloInput
                                    onChange={(e) => { onProfileInputChange(e, 'fees') }}
                                    value={profileData.fees}
                                    placeholder="500"
                                />
                            </div>
                        </div>
                        <div className="section">
                            <div className="input-field">
                                <div className="label">Address</div>
                                <YoloInput
                                    onChange={(e) => { onProfileInputChange(e, 'address') }}
                                    value={profileData.address}
                                    placeholder="Address"
                                />
                            </div>
                        </div>
                        <div className="section">
                            <div className="input-field file-input">
                                <label for="files" className="label">Degree & Certificattions</label>
                                <input
                                    type="file"
                                    id='file'
                                    onChange={(e) => { onDocumentUpload(e, 'documents') }}
                                    style={{ display: "none" }}
                                    ref={fileInput}
                                />
                                {uploadedDocuments.map((certi, i) => (
                                    <div key={i} className="certificates">
                                        <a href={certi.doc_url} target="_blank" rel="noopener noreferrer">{certi.doc_title}</a>
                                        <i onClick={() => removeDocument(i)} className="fa fa-times" aria-hidden="true"></i>
                                    </div>
                                ))}
                                <div className="upload-certificate-btn" onClick={handleClick} > <span>+</span> Add Certificate</div>
                            </div>
                            <div className="input-field">
                                <div className="label">Registration Number</div>
                                <YoloInput
                                    onChange={(e) => { onProfileInputChange(e, 'registration_number') }}
                                    value={profileData.registration_number}
                                    placeholder="PS6735"
                                />
                            </div>
                        </div>
                        <div className="section btn-section">
                            <div className="btn cancel-btn" onClick={props.history.goBack}>Cancel</div>
                            <div className="btn submit-btn" onClick={() => callDoctorUpdateProfileDetailsApi()} >Submit</div>
                        </div>
                    </div>
                </section>
            }
        </SidebarComponent>
    )
}

export default withRouter(UpdateProfile)