import React, { createContext, useReducer, useContext, useEffect } from 'react';

// Initial state for the form
const initialState = {
    answers: {}
};

// Action types
const SET_ANSWER = 'SET_ANSWER';
const CLEAR_FORM = 'CLEAR_FORM';
const formReducer = (state, action) => {
    switch (action.type) {
        case SET_ANSWER:
            const { questionId, answer } = action.payload;

            // Check if it's a sub-question by the presence of a dot in the ID
            if (typeof questionId === 'string' && questionId.includes('.')) {
                const [mainQuestionId, subQuestionId] = questionId.split('.');
                return {
                    ...state,
                    answers: {
                        ...state.answers,
                        [mainQuestionId]: {
                            ...(state.answers[mainQuestionId] || {}),
                            [subQuestionId]: answer
                        }
                    }
                };
            } else {
                return {
                    ...state,
                    answers: {
                        ...state.answers,
                        [questionId]: answer
                    }
                };
            }
            case CLEAR_FORM:
                return initialState;
        default:
            return state;
    }
};

// Create context
const FormContext = createContext();

// Provider component
export const ContextDataProvider = ({ children }) => {
    const [state, dispatch] = useReducer(formReducer, initialState);

    const setAnswer = (questionId, answer) => {
        dispatch({ type: SET_ANSWER, payload: { questionId, answer } });
    };
    const clearForm = () => {
        dispatch({ type: CLEAR_FORM });
    };

    return (
        <FormContext.Provider value={{ state, setAnswer,clearForm }}>
            {children}
        </FormContext.Provider>
    );
};

// Custom hook to use the form context
export const useFormContext = () => useContext(FormContext);
