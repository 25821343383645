import React from 'react';
import { Link } from 'react-router-dom'
import ShowMoreText from 'react-show-more-text';
import $ from 'jquery';
import { dashboard, notifications, mark_seen_notification, mark_seen_all_notifications, appointments_today } from '../utils/api';
import moment from 'moment';
import Loader from './loader';
import SidebarComponent from './SidebarComponent';
// import StatusToggle from './StatusToggle';
// import CalendarDay from './CalendarDay';
import DashboardDP from '../assets/images/dashboard-dp.png';
import SliderArrow from '../assets/images/availability-slider-arrow.png';
import horizontalSliderActiveArrow from '../assets/images/active-horizontal-arrow.png';
import horizontalSliderDeactiveArrow from '../assets/images/deactive-horizontal-arrow.png';
// import sliderCardImg from '../assets/images/slider-card-image.png';
import DailyTimingModal from './DailyTimingModal';


const Dashboard = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [slotsSlider, setSlotsSlider] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [dashboardData, setDashboardData] = React.useState({
    doctor: {
      name: "",
      email: "",
      mobile: "",
      profile_pic: DashboardDP,
      specialization: "",
      qualifications: "",
      is_available: true
    },
    apppointments: {
      today: 0,
      upcoming: 0,
      missed: 0
    },
    hours: 5.0
  })
  const [notifications_list, setNotifList] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);
    dashboard((res) => {
      setDashboardData(res.data.data)
      setLoading(false);
    })

    notifications((res) => {
      setNotifList(res.data.data)
    })
  }, [])

  const deleteNotification = (id) => {
    mark_seen_notification(id, (res) => {
      setNotifList(notifications_list.filter(n => n.id !== id))
    })
  }

  const reload = () => {
    dashboard((res) => {
      setDashboardData(res.data.data)
    })
  }

  const deleteAll = () => {
    mark_seen_all_notifications((res) => {
      setNotifList([])
    })
  }

  const handleModalWrapperClick = (e) => {
    if (e.target !== e.currentTarget) return;
    setShowModal(false)
  }

  const [appointmentsToday, setAppointmentsToday] = React.useState([]);
  React.useEffect(() => {
    appointments_today((res) => {
      setAppointmentsToday(res.data.data)
    })
  }, [])

  const [scrollCount, setScrollCount] = React.useState(0);
  const scrollHorzontal = (direction, className) => {
    setScrollCount(scrollCount + direction)
    let far = $('.' + className).width() / 1.3 * direction;
    let pos = $('.' + className).scrollLeft() + far;
    $('.' + className).animate({ scrollLeft: pos }, 800)

  }

  const executeOnClick = (isExpanded) => {
    console.log(isExpanded);
  }
  
  return (

    <>
      <SidebarComponent reload={reload}>
        {loading ? <div className="container center"><Loader /></div> :
          <div className="dashboard">
            <div className="profile-detail">
              <div className="top-section">
                <div className="profile-section">
                  <div className="image-and-name">
                    <Link className=''>
                      <img src={dashboardData.doctor.profile_pic} alt="" />
                    </Link>
                    <div className="doc-info">
                      <div className="name">Dr. {dashboardData.doctor.name}</div>
                      <div className="profession">{dashboardData.doctor.specialization} {dashboardData.doctor.qualifications}</div>
                    </div>
                  </div>
                </div>

                <div className="mid-line"></div>
                <div className="appointment-counts">
                  <div className="today">
                    <div className="today-count">{dashboardData.apppointments.today}</div>
                    <div>Appointments</div>
                  </div>
                  <div className="missed">
                    <div className="missed-count">{dashboardData.apppointments.missed}</div>
                    <div>Missed</div>
                  </div>
                  <div className="upcoming">
                    <div className="upcoming-count">{dashboardData.apppointments.upcoming}</div>
                    <div>Upcoming</div>
                  </div>
                </div>
                <div className="availability-section">
                  <div className={slotsSlider ? "availability-top-section-1 availability-top-section" : "availability-top-section"}>
                    <div className="headings">
                      <div className="heading-text">
                        Today's Availability
                  </div>
                      <div className="hours-available">
                        {dashboardData.hours} hours Marked
                  </div>
                    </div>
                    <div >
                      <img className={slotsSlider ? 'slider-arrow slider-arrow-rotate' : 'slider-arrow'} src={SliderArrow} alt="" onClick={() => setSlotsSlider(!slotsSlider)} />
                    </div>
                  </div>
                  {slotsSlider ?
                    <div className="slots-section animate__animated animate__fadeIn">
                      {dashboardData.slots.map((slot, i) =>
                        <div className="slot-box" key={i}>
                          {slot}
                        </div>
                      )}
                    </div>
                    : ''}
                </div>
              </div>
              <div className="today-summary-section">
                <div className="summary-header">
                  <div className="heading">Today's Summary</div>
                  <div className="arrows">
                    {appointmentsToday.length > 1 ?
                      <>
                        {scrollCount > 0 ?
                          <img className="enable-left" src={horizontalSliderActiveArrow} alt="" onClick={() => scrollHorzontal(-1, 'cards-slider')} />
                          :
                          <img className="disable-left" src={horizontalSliderDeactiveArrow} alt="" />
                        }
                        {scrollCount === appointmentsToday.length ?
                          <img className="disable-right" src={horizontalSliderDeactiveArrow} alt="" />
                          :
                          <img className="enable-right" src={horizontalSliderActiveArrow} alt="" onClick={() => scrollHorzontal(1, 'cards-slider')} />
                        }
                      </>
                      :
                      <>
                        <img className="disable-left" src={horizontalSliderDeactiveArrow} alt="" />
                        <img className="disable-right" src={horizontalSliderDeactiveArrow} alt="" />
                      </>
                    }
                  </div>
                </div>
                <div className="cards-slider">
                  {appointmentsToday.length > 0 ?
                    <>
                      {appointmentsToday.map((app, i) =>
                        <Link key={i} className="appointment-card"
                          to={{
                            pathname: 'appointments/' + app.id,
                            state: { appointment: app }
                          }}
                        >
                          <div className="top-row">
                            <div className="patient-details">
                              <img src={app.patient.photo_url} className="" alt="" />
                              <div>
                                <div className="name">{app.patient.name}</div>
                                <div className="age">{app.patient.age} Yrs, {app.patient.gender}</div>
                              </div>
                            </div>
                            {app.status === 'missed' ?
                              <div className="missed-appointment-mark">MISSED</div>
                              : ''
                            }
                            {app.status === 'upcoming' ?
                              <div className="upcoming-appointment-mark">SCHEDULED</div>
                              : ''
                            }
                            {app.status === 'completed' ?
                              <div className="upcoming-appointment-mark">COMPLETED</div>
                              : ''
                            }
                          </div>
                          <div className="reason">
                            <ShowMoreText
                              lines={1}
                              more='read more'
                              less='show less'
                              anchorClass='read-full'
                              onClick={()=>executeOnClick()}
                              expanded={false}
                            >
                              {app.title}
                            </ShowMoreText>
                          </div>
                          <div className="bottom-row">
                            <div className="card-appointment-time">Today - {moment(new Date("1970-01-01 " + app.start_time)).format('LT')} - {moment(new Date("1970-01-01 " + app.end_time)).format('LT')}</div>
                            <div className="appointment-number">{app.appointment_str}  Appointment</div>
                          </div>
                        </Link>
                      )}
                    </>
                    : <div className="no-appointment"> No apporintment Today </div>
                  }
                </div>
              </div>
            </div>

            <div className="right-section">
              <div className="notification-section">
                <div className="heading">
                  <div className="notif-counts">{notifications_list.length} New Notifications</div>
                  <div className="clear-notif" onClick={deleteAll} >Clear all</div>
                </div>
                <div className="notification-list">
                  {notifications_list.slice(0).reverse().map((notif, i) => (
                    <div key={i} className="notification">
                      {/* <div className="time">{notif.readable_time.split(' ')[3]}</div> */}
                      <div className="info-txt">
                        <span>{notif.text} {notif.doctor_text} at {notif.start_time} on {notif.time}</span>
                        <div className="notification-time">{moment(new Date(notif.readable_time)).calendar()}</div>
                      </div>
                      <div className="flex-filler"></div>
                      <div className="cross-btn" onClick={() => deleteNotification(notif.id)}>
                        {/* <i className="fa fa-times"></i> */}
                    x
                  </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="top-section">
            <div className="appointments-detail">
              <div className="date">
                <div className="text"> <span className="day">Apr 10</span> Monday </div>
              </div>
              
            </div>
            <div className="hours-section">
                <div className="hour">{dashboardData.hours}</div>
                <div className="hrs">Hours</div>
                <div className="text"> marked available</div>
                <div className="add-more-btn" onClick={()=>setShowModal(true)}> 
                  <div className="txt">Change Availability</div>
                </div>
            </div>
          </div>
          <div className="calendar-section">
            <CalendarDay />
          </div> */}
            </div>
          </div>
        }
      </SidebarComponent>
      { showModal ?
        <div className="modal-wrapper" onClick={handleModalWrapperClick}>
          <DailyTimingModal closeModal={() => setShowModal(false)} reload={reload} />
        </div> : null}
    </>
  )
}

export default Dashboard
