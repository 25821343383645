export const getToken = () => {
  return localStorage.getItem('token')
}

export const saveToken = (token) => {
  return localStorage.setItem('token', token)
}

export const removeToken = () => {
  localStorage.removeItem("token");
}

export const saveQBDetails = (id, username, password) => {
  localStorage.setItem('qb_id', id);
  localStorage.setItem('qb_username', username);
  localStorage.setItem('qb_password', password);
}

export const getQBDetails = () => {
  return {
    qb_id: localStorage.getItem('qb_id'),
    qb_username: localStorage.getItem('qb_username'),
    qb_password: localStorage.getItem('qb_password')
  }
}

export const getValidityToken = () => {
  return localStorage.getItem('validity_token')
}

export const saveValidityToken = (validity_token) => {
  return localStorage.setItem('validity_token', validity_token)
}
