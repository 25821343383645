import React from 'react'
import { getToken } from '../utils/auth';


const Home = (props) => {   
    const token = getToken()
        
    React.useEffect(() => {
        const checkToken = () => {
            if (token === null){
                window.location.href = 'https://stghome.yolohealth.co.in/login/doctor'; 
            }else{
                props.history.push('/dashboard')
            }    
        }
        checkToken()
    },[props.history, token]);

    

    return (
        <div className="loader">
            
        </div>
    )
}

export default Home
