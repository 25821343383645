import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import YoloLogo from '../assets/images/main-logo.png';
import { removeToken, getValidityToken } from '../utils/auth';
import { check_token } from '../utils/api'

const Sidebar = (props) => {
    const links = [
        { title: 'Dashboard', src: 'caseStudiesGovt', to: '/dashboard', icon: 'th-large' },
        { title: 'Appointments', src: 'caseStudiesHospi', to: '/appointments', icon: 'clock' },
        { title: 'Calendar', src: 'caseStudiesRP', to: '/calendar', icon: 'calendar' },
        { title: 'Payments', src: 'caseStudiesCH', to: '/payments', icon: 'wallet' }
    ]

    const logout = () => {
        removeToken()
        window.location.href = 'http://stghome.yolohealth.co.in/login/doctor';
    }
    const setError = props.setError;
    const setIsOnline = props.setIsOnline;

    React.useEffect(() => {

        setInterval(() => {
            fetch("https://s3.ap-south-1.amazonaws.com/yolo.django.stg/do_not_delete.txt", { cache: "no-store" })
                .then(function () {
                    setIsOnline(true)
                    check_token(getValidityToken(), (res) => {
                        setError(null)
                        if (res.data.status.result === 1) {
                            console.log(res.data.status.message)
                        } else {
                            logout()
                        }
                    }, (err) => { setError(true) })
                }).catch(function () {
                    setIsOnline(false)
                    console.log('its not you its us')
                });

        }, 10000)


    }, [setError, setIsOnline])

    let location = useLocation().pathname;
    const getClassName = (currentLocation) => {
        if (currentLocation === '/appointments' && location.slice(0, 13) === '/appointments') {
            return 'links active'
        } else if (location === currentLocation) {
            return 'links active'
        } else {
            return 'links'
        }
    }

    return (
        <div className="sidebar">
            <div className="logo">
                <Link to="/dashboard"><img src={YoloLogo} alt="" /></Link>
            </div>
            <div className="linksets">
                {links.map((row, i) => (
                    <div className={getClassName(row.to)} key={i}>
                        <img alt="" />
                        <Link
                            to={row.to}
                            className='link'
                        >
                            <i className={"fa fa-" + row.icon + (location === row.to ? ' blue-i' : '')}></i>{row.title}
                        </Link>
                    </div>
                ))}
            </div>
            <div className="logout-btn" onClick={() => logout()}>
                <span>Logout</span>
            </div>
        </div>
    )
}

export default Sidebar
