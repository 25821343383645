import React from 'react';
import moment from 'moment';
import { get_general_timings, set_general_timings } from '../utils/api';

const TimePicker = ({ val, onChange }) => {
  let times = ['00:00 AM']
  let start = moment("00:00 AM", 'hh:mm A')
  for (var i = 0; i < 95; i++) {
    times.push(start.add(15, 'minutes').format('hh:mm A'))
  }
  return (
    <select onChange={onChange} className="time-picker">
      {times.map((time, i) => <option key={i} selected={time === val} value={time}>{time}</option>)}
    </select>
  )
}

const TimingRow = ({ data, onDelete, setData, isInvalid }) => {
  const setStart = (e) => {
    setData({
      ...data,
      start: e.target.value
    })
  }
  const setEnd = (e) => {
    setData({
      ...data,
      end: e.target.value
    })
  }

  return (
    <div className={isInvalid ? 'timing-row-invalid' : 'timing-row-wrapper'}>
      <div className="text">
        From <TimePicker val={data.start} onChange={setStart} /> To <TimePicker val={data.end} onChange={setEnd} />
      </div>
      <div className="flex-filler"></div>
      <div className=' circle-icon circle-cross' style={{ backgroundColor: 'white' }} onClick={onDelete}>
        ✖
      </div>
    </div>
  );
}

const TimingModal = ({ closeModal, reload }) => {

  const [timingData, setTimingData] = React.useState([])
  const [message, setMessage] = React.useState(null)

  const deleteRow = (i) => {
    setTimingData(timingData.filter((t, j) => j !== i))
  }

  React.useEffect(() => {
    get_general_timings((res) => {
      setTimingData(res.data.data)
    })
  }, [])

  const addRow = () => {
    setTimingData([
      ...timingData,
      {
        start: "11:00 AM",
        end: "1:00 PM",
      }
    ])
  }

  const handleTimingChange = (i, p) => {
    setTimingData(timingData.map((t, j) => j !== i ? t : p))
  }

  const handleSubmit = () => {
    set_general_timings(timingData, () => {
      if (reload) { reload() }
      setMessage(true)
    })
  }

  const isRowInValid = (i) => {
    const row = timingData[i];
    const rs = moment(row.start, "hh:mm a");
    const re = moment(row.end, "hh:mm a");
    for (var j = 0; j < i; j++) {
      var _row = timingData[j];
      var _s = moment(_row.start, "hh:mm a");
      var _e = moment(_row.end, "hh:mm a");
      if ((rs >= _s && rs <= _e) || (re >= _s && re <= _e)) {
        return true
      }
    }
    return false
  }

  const allRowsValid = () => {
    for (var i = 0; i < timingData.length; i++) {
      if (isRowInValid(i)) {
        return false;
      }
    }
    return true;
  }

  return (
    <div className={message ? "modal general-timing-modal" : "modal"}>
      <i className="cross fa fa-times"
        onClick={closeModal}
      ></i>
      {message ? <p>Clinic Timings Has Been Updated!</p> :
        <>
          <h3>Set general timings for daily</h3>
          {
            timingData.map((t, i) => <TimingRow
              key={i}
              data={t}
              isInvalid={isRowInValid(i)}
              onDelete={() => deleteRow(i)}
              setData={p => { handleTimingChange(i, p) }} />)
          }
          <div style={{ display: 'flex', paddingTop: 20 }}>
            <div className="flex-filler"></div>
            <div className='circle-icon' onClick={addRow}>
              <i className="fa fa-plus"></i>
            </div>
          </div>
          <div style={{ display: 'flex', paddingTop: 20 }}>
            <div className="flex-filler">
              {
                allRowsValid() ? ''
                :
                <div className="slots-error">Slots are overlapping</div>
                
              }
            </div>
            {
              allRowsValid() && <div class='green-button' onClick={handleSubmit}>
                Confirm
              </div>
            }
          </div>
        </>
      }
    </div>
  );
}

export default TimingModal;