import React from 'react';
import Sidebar from './sidebar.js'
import Navbar from './navbar.js'

const SidebarComponent = (props) => {
  const [error, setError] = React.useState(null);
  const [isOnline , setIsOnline] = React.useState(true);

  // const closeErrorWrapper = () => {
  //   setError(null)
  // }

  return (
    <>
      <Sidebar setError={setError} setIsOnline={setIsOnline}/>
      <div className="right-side-container">
        <Navbar reload={props.reload}/>
        {error ?
        <div className="modal-wrapper"  >
          <div className="internet-conn-error-box">
            <span>Server is down.please inform the concerned team</span>
          </div>
        </div>
        :
        <div className="main-content">
          {props.children}
        </div>
        }
        {
          isOnline ? '' :
          <div className="modal-wrapper"  >
            <div className="internet-conn-error-box">
              <span> Please check your internet Connection </span>
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default SidebarComponent;
