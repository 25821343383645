import React from 'react';
import { create_call_log, call_heartbeat } from '../utils/api';
import { withRouter } from 'react-router-dom';
import Fullscreen from "react-full-screen";
import ChatBoxComponent from './ChatBoxComponent';
import PhoneCallIcon from '../assets/images/phone-call-icon.png';


const ConfirmModal = (props) => {
    const handleModalWrapperClick = (e) => {
        if (e.target !== e.currentTarget) return;
        props.onClose();
    }

    return <div className="modal-wrapper" onClick={handleModalWrapperClick}>
        <div style={{ backgroundColor: "white", padding: "40px" }}>
            <div>Are you sure you want to end the call?</div>
            <div style={{ display: "flex", justifyContent: "center", paddingTop: "30px" }}>
                <button style={{ margin: "5px" }} onClick={props.endCall}>Yes</button>
                <button style={{ margin: "5px" }} onClick={props.onClose} >No</button>
            </div>
        </div>
    </div>
}


const VideoChat = (props) => {
    const [showLiveDataBox, setShowLiveDataBox] = React.useState(null);
    const [logId, setLogId] = React.useState(null);
    const [videoMuted, setVideoMuted] = React.useState(false);
    const [audioMuted, setAudioMuted] = React.useState(false);
    const [audioMutedPatient, setAudioMutedPatient] = React.useState(false);
    const [session, setSession] = React.useState({});
    const [fullScreenMode, setfullScreenMode] = React.useState(false);
    const [heartbeatId, setHeartbeatId] = React.useState(null);
    const [endCallModalVisible, setEndCallModalVisible] = React.useState(null);


    React.useEffect(() => {
        // test
        if (props.location.query) {

            if (logId === null) {
                create_call_log(props.location.query.appointment_id, (res) => {
                    setLogId(res.data.data.log_id,);
                    console.log("log created", res.data)

                })
            } else {
                var mediaParams = {
                    audio: true,
                    video: true,
                    options: {
                        muted: true,
                        mirror: true
                    },
                    elemId: "localVideo"
                };
                props.location.query.callSession.callSession.getUserMedia(mediaParams, function (err, stream) {
                    if (err) {
                        console.log('error-------')
                        console.log(err)
                    } else {
                        console.log('get user media-------')
                        props.location.query.callSession.callSession.accept(props.location.query.callExtension.callExtension);
                    }
                });

                props.location.query.callSession.callSession.onRemoteStreamListener = function (session, userID, remoteStream) {
                    session.attachMediaStream("remoteOpponentVideoElementId", remoteStream);
                };
                props.location.query.callSession.callSession.on = function (session, userID, remoteStream) {
                    session.attachMediaStream("remoteOpponentVideoElementId", remoteStream);
                };


                setSession(props.location.query.callSession.callSession);
            }
        } else {
            props.history.push('/')
        }

    }, [props.location, props.history, logId]);

    React.useEffect(() => {
        if (logId) {
            var hid = setInterval(() => {
                call_heartbeat(logId)
            }, 3000)
            console.log("created interval", hid)
            setHeartbeatId(hid);
        }
    }, [logId])

    React.useEffect(() => {
        if (props.location.query) {
            props.location.query.qb.webrtc.onStopCallListener = function (session, userId, extension) {
                console.log("on stop listener called")
                clearInterval(heartbeatId)
                props.location.query.setCallEndMsg('The Patient hung up the call')
                props.location.query.setCallHangUpModal(true)
                setTimeout(() => {
                    props.location.query.setCallHangUpModal(null)
                    props.location.query.setCallEndMsg(null)
                }, 2000);
                props.history.push('/')
                
            };

            props.location.query.callSession.callSession.onSessionCloseListener = function (session) {
                console.log("on session closed called")
                clearInterval(heartbeatId)
                props.location.query.setCallEndMsg('Call Ended')
                props.location.query.setCallHangUpModal(true)
                setTimeout(() => {
                    props.location.query.setCallHangUpModal(null)
                    props.location.query.setCallEndMsg(null)
                }, 1500);
                props.history.push('/')
            };

            console.log("setting the session changed listener")
            props.location.query.callSession.callSession.onSessionConnectionStateChangedListener = function (session, userID, connectionState) {
                console.log("session changed", connectionState)
                if (connectionState === 4) {
                    clearInterval(heartbeatId)
                    props.location.query.callSession.callSession.stop({})
                    props.history.push('/')
                }
            };
        } else {
            props.history.push('/')
        }
    }, [props.location, props.history, heartbeatId])

    const callEnd = () => {
        clearInterval(heartbeatId);
        props.location.query.callSession.callSession.stop({});
        props.history.push('/')
    }

    const toggleVideo = () => {
        if (videoMuted) {
            session.unmute('video');
        } else {
            session.mute('video');
        }
        setVideoMuted(!videoMuted);
    }

    const toggleAudio = () => {
        if (audioMuted) {
            session.unmute('audio');
        } else {
            session.mute('audio');
        }
        setAudioMuted(!audioMuted);
    }

    const toggleAudioPatient = () => {
        const videoelem = document.getElementById('remoteOpponentVideoElementId');
        videoelem.muted = !audioMutedPatient;
        setAudioMutedPatient(!audioMutedPatient);
    }

    return (
        <div className="video-chat">
            <div className="video-box">
                <Fullscreen enabled={fullScreenMode} onChange={isFull => setfullScreenMode(isFull)}>
                    <video id='remoteOpponentVideoElementId' />
                    <i onClick={() => setfullScreenMode(!fullScreenMode)} class={fullScreenMode ? "fa fa-compress-alt" : "fa fa-expand"} aria-hidden="true"></i>
                    <div className="buttons">
                        {videoMuted ? <i class="fas fa-video-slash" onClick={toggleVideo}></i> : <i class="fas fa-video" onClick={toggleVideo}></i> }
                        {audioMutedPatient ? <i class="fas fa-volume-mute" onClick={toggleAudioPatient}></i>   : <i class="fas fa-volume-up" onClick={toggleAudioPatient}></i>} 
                        { audioMuted ? <i class="fas fa-microphone-slash" onClick={toggleAudio}></i> : <i class="fas fa-microphone" onClick={toggleAudio}></i>}
                        <img className="call-end" onClick={() => setEndCallModalVisible(true)} src={PhoneCallIcon} alt="" />
                    </div>
                    <div className="videocall-self-image" >
                        <video id='localVideo' />
                    </div>
                </Fullscreen>
                <div className="live-data-btn">
                    {
                        showLiveDataBox ?
                            <span>⨉</span>
                            :
                            <span>&#60;</span>
                    }
                    <span class="text" onClick={() => setShowLiveDataBox(!showLiveDataBox)}>Live Data</span>
                </div>
            </div>
            <div className={showLiveDataBox ? "video-chatbox-live-data video-chatbox" : "video-chatbox"} >
                {
                    showLiveDataBox ?
                        <div className="live-data-container">
                            <div className="live-data-counts">
                                <div className="sys">
                                    <div>SYS</div>
                                    <div><span>123</span>mmHg</div>
                                </div>
                                <div>
                                    <div>SYS</div>
                                    <div><span>123</span>mmHg</div>
                                </div>
                                <div>
                                    <div>SYS</div>
                                    <div><span>123</span>mmHg</div>
                                </div>
                                <div>
                                    <div>SYS</div>
                                    <div><span>123</span>mmHg</div>
                                </div>
                            </div>
                            <div className="ecg-heading">ECG Data</div>
                        </div>
                        : null
                }
                <ChatBoxComponent
                    appointment_id={props.location.query ? props.location.query.appointment_id : ''}
                    patient={props.location.query ? props.location.query.patient : ''}
                    prescriptions={props.location.query ? props.location.query.prescriptions : ''}
                    documents={props.location.query ? props.location.query.documents : ''}
                    dialog_id={props.location.query ? props.location.query.dialog_id : ''}
                    pathname={props.location.pathname}
                    local_id="localVideo"
                    remote_id="remoteOpponentVideoElementId"
                />
            </div>
            { endCallModalVisible && <ConfirmModal
                onClose={() => { setEndCallModalVisible(false) }}
                endCall={() => { setEndCallModalVisible(false); callEnd() }}
            />}
        </div>
    )
}

export default withRouter(VideoChat)
