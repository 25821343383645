/* global QB */
import React from 'react';
// import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getQBDetails } from '../utils/auth';
import { appointmentDetails } from '../utils/api';
import ringtone from '../assets/basic_ringtone.mp3';
var Sound = require('react-sound').default;
// import { Sound } from 'react-sound';

const Loader = () => {
    return (
        <div className="ldsring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

const QuickBlox = (props) => {
    const [incomingCall, setIncomingCall] = React.useState(null);
    const [callHangUpModal, setCallHangUpModal] = React.useState(null);
    const [callExtension, setCallExtension] = React.useState(null);
    const [qb, setQb] = React.useState(null);
    const [appointmentDetail, setAppointmentDetail] = React.useState(null);
    const [callSession, setCallSession] = React.useState(null);
    const [soundPosition, setSoundPosition] = React.useState(0);
    const [callEndMsg, setCallEndMsg] = React.useState(null);
    console.log('quick blox loading')
    console.log(props)
    // React.useEffect(()=>{
    //     const onMessage = (userId, message) => {
    //         if(message && userId === 112077525){
    //             console.log('chatHistory')
    //             console.log(chatHistory)
    //             let receivedMsg = {}
    //             receivedMsg['message'] = message.body;
    //             receivedMsg['sender_id'] = 112077525;
    //             setChatHistory([...chatHistory, receivedMsg])
    //         }
    //     }


    //     if(qb){
    //         qb.chat.onMessageListener = onMessage;
    //     }

    // }, [qb, chatHistory])

    React.useEffect(() => {
        const quickbloxApis = () => {


            var params = { login: getQBDetails().qb_username, password: getQBDetails().qb_password };
            var CONFIG = {
                webrtc: {
                    autoReject: false,
                }
            };
            var CREDENTIALS = {
                appId: '80293',
                authKey: 'tPWasabEppO8jpT',
                authSecret: 'xVHuJFqghs3ESsB'
            };
            QB.init(CREDENTIALS.appId, CREDENTIALS.authKey, CREDENTIALS.authSecret, CONFIG);

            setQb(QB);

            QB.createSession(params, function (err, result) {
                if (result) {
                    QB.login(params, function (err, result) {
                        if (result) {
                            var userCredentials = {
                                userId: getQBDetails().qb_id,
                                password: getQBDetails().qb_password
                            };

                            QB.chat.connect(userCredentials, function (error, contactList) {

                                QB.webrtc.onCallListener = function (session, extension) {
                                    setCallSession(session);
                                    setCallExtension(extension);
                                    console.log("Extension: ", extension)
                                    setIncomingCall(true)
                                    if(!extension.appointment_id){
                                        console.log('appointment id not defined---')
                                        extension.appointment_id = 7      
                                    }
                                    appointmentDetails(extension.appointment_id, (res) => {
                                        if (res.data.status.result === 1) {
                                            setAppointmentDetail(res.data.data)
                                        } else {
                                            setIncomingCall(null)
                                            session.reject(extension);
                                        }
                                    }, (err) => { session.reject(extension); setIncomingCall(null) })
                                };

                                QB.webrtc.onStopCallListener = function (session, extension) {
                                    console.log('on call rejected ')
                                    setCallSession(null);
                                    setCallExtension(null);
                                    setIncomingCall(null);
                                    setCallHangUpModal(true)
                                    setTimeout(() => {
                                        setCallHangUpModal(null)
                                    }, 1000);
                                };

                            });
                        }
                    });
                }
            });
        }
        quickbloxApis();

    }, []);

    const handleModalWrapperClick = (e, callback) => {
        if (e.target !== e.currentTarget) return;
        // callback()
        // callSession.reject(callExtension);
    }



    const callDeclined = () => {
        setIncomingCall(null)
        callSession.reject(callExtension);
    }

    const ordinalSuffixOf = (i) => {
        var j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return "st";
        }
        if (j === 2 && k !== 12) {
            return "nd";
        }
        if (j === 3 && k !== 13) {
            return "rd";
        }
        return "th";
    }

    const handlePlaying = (ev) => {
        setSoundPosition(ev.position)
    }
    
    const handleFinishedPlaying = () => {
        setSoundPosition(0)
    }

    return (
        <>
            {
                incomingCall ?
                    <div className="modal-wrapper" onClick={(e) => handleModalWrapperClick(e, () => setIncomingCall(!incomingCall))}>
                        <div className="incoming-call-modal" >
                            {appointmentDetail ?
                                <>
                                    <div className="caller-detail">

                                        <div className="image"> <img
                                            style={{
                                                height: "100px",
                                                width: "100px",
                                                borderRadius: "50%"
                                            }}
                                            src={appointmentDetail ? appointmentDetail.patient.profile_pic : ''} alt="" /> </div>
                                        <div className="details">
                                            <div className="caller-name"><span>{appointmentDetail.patient.name}</span> ({appointmentDetail.appointment_number}<sup>{ordinalSuffixOf(appointmentDetail.appointment_number)}</sup> appointment) </div>
                                            <div className="caller-other-details">
                                                <div className="age">
                                                    <span className="heading">Age</span>
                                                    <span>{appointmentDetail.patient.age}</span>
                                                </div>
                                                <div className="gender">
                                                    <span className="heading">Gender</span>
                                                    <span>{appointmentDetail.patient.gender}</span>
                                                </div>
                                                {/* <div className="city">
                                <span className="heading" >City</span>
                                <span>Mumbai</span>
                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="central-line"></div>
                                    {/* // <div className="caller-location">
                    // Calling from <span>Kiosk</span> at Alburquerque, Mexico
                // </div>  */}
                                    <div className="action-buttons">
                                        <button className="decline-btn" >
                                            <Link onClick={() => callDeclined()} className=''> Decline</Link>
                                        </button>
                                        <button className="accept-btn">
                                            <Link onClick={() => setIncomingCall(!incomingCall)}
                                                to={appointmentDetail ? {
                                                    pathname: '/video-chat',
                                                    query: {
                                                        callSession: { callSession },
                                                        callExtension: { callExtension },
                                                        qb: qb,
                                                        patient: appointmentDetail.patient,
                                                        dialog_id: appointmentDetail.dialog_id,
                                                        appointment_id: appointmentDetail.id,
                                                        prescriptions: appointmentDetail.prescriptions,
                                                        documents: appointmentDetail.documents,
                                                        setIncomingCall: { setIncomingCall },
                                                        setCallHangUpModal: setCallHangUpModal,
                                                        setCallEndMsg: setCallEndMsg
                                                    }
                                                } : ''}
                                            > Accept</Link>
                                        </button>
                                    </div>
                                    <Sound
                                        url={ringtone}
                                        playStatus={Sound.status.PLAYING}
                                        volume={100}
                                        position={soundPosition}
                                        onPlaying={(p)=>handlePlaying(p)}
                                        onFinishedPlaying={()=>handleFinishedPlaying()}
                                    />
                                </>
                                : <div className="container center"><Loader /></div>
                            }
                        </div>
                    </div>
                    : ""
            }
            {
                callHangUpModal ?
                <div className="hangup-call-wrapper" >
                    <div className="call-hangup-modal">{callEndMsg}</div>
                </div>
                : ''
            }
        </>
    )
}

export default QuickBlox