import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { getToken } from '../utils/auth'

function PrivateRoute({component: Component, ...props}) {
  const token = getToken()
  console.log()
  return (
    <Route
      {...props}
      render={({ location }) => token == null ? (
        <Redirect
          to={{
            path: 'https://stghome.yolohealth.co.in/login/doctor',
            state: { from: location }
          }}
        />
      ) : <Component {...props} />}
    />
  );
}

export default PrivateRoute
