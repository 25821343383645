import React, { useRef } from 'react';
import { calendar } from '../utils/api';
import SidebarComponent from './SidebarComponent';
import TimingModal from './TimingModal';
import Loader from './loader';
import moment from 'moment';

const Event = (props) => {
  console.log('props.appointment')
  console.log(props.appointment)
  const appointment = props.appointment
  const [detailVisible, setDetailVisible] = React.useState(false)
  const [outsideClickedRef, setOutsideClickedRef] = React.useState(false)

  function useOutsideAlerter(ref) {
    React.useEffect(() => {
      function handleClickOutside(event) {
        setOutsideClickedRef(ref.current)
        if (ref.current && !ref.current.contains(event.target)) {
          setDetailVisible(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const detailModalRef = useRef(null);
  useOutsideAlerter(detailModalRef);



  return (
    <div className="box-small" style={{ position: "relative" }}>
      <a href={"/appointments/" + appointment.id} >{appointment.appointment_str} Appointment with <span>{appointment.patient.name}</span></a>
      <div className="flex-filler"></div>
      <i className="fa fa-ellipsis-v" onClick={() => setDetailVisible(outsideClickedRef ? '' : !detailVisible)}
      ></i>
      { detailVisible ?
        <div className={props.ColumnNumber === 2 ? 'appointment-detail-modal-bottom appointment-detail-modal' : 'appointment-detail-modal'} ref={detailModalRef}>
          <div className="timings">
            <div className="date"> {appointment.str_date} </div>
            <div className="appointent-time"> {moment(appointment.start_time, 'hh:mm:ss').format('LT')} </div>
            <div className="duration"> (15 MINS) </div>
          </div>
          <div className="profile-detail">
            <img src={appointment.patient.photo_url} alt="" style={{ height: "40px", width: "40px" }} />
            <div className="name-age">
              <div className="name">{appointment.patient.name}</div>
              <div className="age">{appointment.patient.age}, {appointment.patient.gender}</div>
              {/* <div className="past-appointments">3, Past appointments</div> */}
            </div>
          </div>
          <div className="underline"></div>
          <div className="actions">
            <div><a href={'/appointments/' + appointment.id + '/notes/'}>Add notes</a></div>
            <div className="active"><a href={'/appointments/' + appointment.id}>Send messsage</a></div>
          </div>
        </div>
        : null}
    </div>
  )
}

const Calendar = () => {
  let times = ['00:00 AM']
  let start = moment("00:00 AM", 'hh:mm A')
  for (var i = 0; i < 95; i++) {
    times.push(start.add(15, 'minutes').format('hh:mm A'))
  }

  const [showModal, setShowModal] = React.useState(false);
  const [today, setToday] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [calendarData, setCalendarData] = React.useState([
    { appointments: [] }, { appointments: [] }, { appointments: [] }
  ])

  const getDatePickerText = () => {
    const start = moment().add(today, 'days').format("MMM Do")
    const end = moment().add(today + 2, 'days').format("MMM Do")
    return start + ' - ' + end
  }

  React.useEffect(() => {
    const start = moment().add(today, 'days').format("YYYY-MM-DD")
    setLoading(true);
    calendar(start, (res) => {
      setCalendarData(res.data.data)
      setLoading(false);
    })
  }, [today])

  const handleModalWrapperClick = (e) => {
    if (e.target !== e.currentTarget) return;
    setShowModal(false)
  }

  const getDateHeaders = () => {
    var dates = []
    var days = []
    for (var i = today; i < today + 3; i++) {
      dates.push(moment().add(i, 'days').format('DD'))
      days.push(moment().add(i, 'days').format('dddd'))
    }

    return dates.map((date, i) => (
      <div className={(i + today) === 0 ? "day-header-selected" : "day-header"}>
        <div className="date"><span>{date}</span></div>
        <div className="day">{days[i]}</div>
      </div>
    ))
  }

  // const AppointmentDetailsModal = ({style}) => (
  //   <div className="appointment-detail-modal" 
  //   style={style}
  //   >  
  //     <div className="timings">
  //       <div className="date"> Today </div> 
  //       <div className="appointent-time"> 11:30 AM </div> 
  //       <div className="duration"> (32 MINS) </div>
  //     </div>
  //     <div className="profile-detail">
  //         <img src= {Patient} alt="" />
  //         <div className="name-age">
  //             <div className="name">vinod</div>
  //             <div className="age">30, M</div>
  //             <div className="past-appointments">3, Past appointments</div>
  //         </div>
  //     </div>
  //     <div className="actions">
  //       <div>Add notes</div>
  //       <div className="active">Send messsage</div>
  //       <div>Reschedule Appointment</div>
  //     </div>
  //   </div>  
  // )

  const get_cell_appointment = (i, j) => {
    const appointments = calendarData[j].appointments.filter(a => a.start_quarter === i)
    return appointments.length && !loading ? <Event ColumnNumber={j} appointment={appointments[0]} /> : null
  }

  return (
    <>
      <SidebarComponent>
        <div className="calendar-container">
          <div className="calendar-tabs">
            <div className="calendar-date-picker">
              <div className="green-arrow" onClick={() => setToday(today - 1)} ><i className="fa fa-angle-left"></i></div>
              <div className="date-text">{getDatePickerText()}</div>
              <div className="green-arrow" onClick={() => setToday(today + 1)}><i className="fa fa-angle-right"></i></div>
            </div>
            <div className="flex-filler"></div>
            <div className="calendar-plus" onClick={() => setShowModal(true)}>
              +
          </div>
            <div className="smallest">
              Mark Available Hours
          </div>
            <div className="google-sync-icon" ></div>
            <div className="google-sync-text">
              Sync with Google
          </div>
          </div>
          <div className="calendar-days">
            <div className="time">
            </div>
            <div className="day-header-wrapper">
              {getDateHeaders()}
            </div>
          </div>
          {loading ? <div className="container center" style={{ paddingTop: "100px" }}><Loader /></div> :
            <>
              <div className="calendar-events">
                {times.map((t, i) => (
                  <div key={t} className="calendar-row">
                    <div className="time">
                      {i % 2 === 0 ? t : ""}
                    </div>
                    <div className="day-section">
                      {get_cell_appointment(i, 0)}
                    </div>
                    <div className="day-section">
                      {get_cell_appointment(i, 1)}
                    </div>
                    <div className="day-section">
                      {get_cell_appointment(i, 2)}
                    </div>
                  </div>
                ))}


              </div>
            </>
          }
        </div>
      </SidebarComponent>
      { showModal ?
        <div className="modal-wrapper" onClick={handleModalWrapperClick}>
          <TimingModal closeModal={() => setShowModal(false)} reload={() => { }} />
        </div> : null}
    </>
  )
}

export default Calendar;