import React from 'react'
import { Link } from 'react-router-dom'
import { appointmentList, filteredAppointmentList } from '../utils/api';
import Loader from './loader';
import SidebarComponent from './SidebarComponent';
import StarIcon from '../assets/images/star-icon.png';
import StarIconActive from '../assets/images/star-icon-active.png';
import moment from 'moment';


const Appointment = (props) => {   
    const [error, setError] = React.useState(null);
    const [appointmentsData, setAppointmentsData] = React.useState([]);
    const [filteredAppointmentsData, setFilteredAppointmentsData] = React.useState([]);
    const [appointmentStatus, setAppointmentStatus] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [filterValues, setFilterValues] = React.useState({
        filterby: "",
    })
    console.log('appointment props')
    console.log(props)
    React.useEffect(() => {
        if(props.location.state){
            if(props.location.state.status==='upcoming'){
                setAppointmentStatus('upcoming')
            }else{
                setAppointmentStatus('completed')
            }
        }else{
            setAppointmentStatus('upcoming')
        }
    }, [props.location.state]);

    React.useEffect(() => {
        const callAppointmentListApi = () => {
            appointmentList( (res)=>{
                setLoading(false);
                if(res.data.status.result === 1){
                    console.log('appointments ')
                    console.log(res.data.data)
                    let dateSortedAppointments = res.data.data.reverse()
                    setAppointmentsData(dateSortedAppointments)
                    setFilteredAppointmentsData(dateSortedAppointments)
                }else{
                    setError(res.data.status.message)
                }
                
            }, (err)=>{setError(false)})
        }
        setLoading(true);
        callAppointmentListApi();
    }, []);


    const onSearchInputChange = (e, name) => {
        let formData = Object.assign({}, filterValues);
        formData[name] = e.target.value;
        setFilterValues(formData);
        if(!e.target.value){
            setFilteredAppointmentsData(appointmentsData)
        }
    }


    const filterAppointmentList = () => {
        if(!filterValues.filterby){
            setFilteredAppointmentsData(appointmentsData)   
        }else{
            filteredAppointmentList(filterValues.filterby, (res)=>{
                setLoading(false);
                if(res.data.status.result === 1){
                    console.log('filtered appointments ')
                    console.log(res.data.data)
                    let filteredAppointments = res.data.data.reverse()
                    setFilteredAppointmentsData(filteredAppointments)
                }else{
                    setError(res.data.status.message)
                }
                
            }, (err)=>{setError(false)})
        }    
    }

    const handleEnterKeyPress= (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
          filterAppointmentList()
        }
    }

    return (
        <SidebarComponent>
        { loading ? <div className="container center"><Loader /></div> :
        <>
        <div className="appointment-container">
            <section>
                <div className="appointment-top-bar">
                    <div className="links">
                        <div>
                            <div  className={appointmentStatus==='upcoming' ? 'active':''} onClick={()=>setAppointmentStatus('upcoming')} >Ongoing</div>
                            <div  className={appointmentStatus==='completed' ? 'active':''} onClick={()=>setAppointmentStatus('completed')}>Completed</div>
                            {/* <div  className={appointmentStatus==='missed' ? 'active':''} onClick={()=>setAppointmentStatus('missed')} >Missed</div> */}
                        </div>    
                        <div className="underline"></div>
                    </div>
                    <div className="search-bar">
                        <input className="input-field" type="text" placeholder="Search by Id/Patient" 
                            onChange={(e) => {onSearchInputChange(e, 'filterby')}}
                            value={filterValues.filterby}
                            onKeyUp={(e)=>handleEnterKeyPress(e)}
                        />
                        <i className="fa fa-search" onClick={()=>filterAppointmentList()}></i>
                    </div>
                </div>

                {filteredAppointmentsData.filter((data)=>data.appointments.filter((f)=>f.status===appointmentStatus || (f.status==='missed' ? appointmentStatus==='upcoming' : '' )).length>0).length > 0 ?
                filteredAppointmentsData.filter((data)=>data.appointments.filter((f)=>f.status===appointmentStatus || (f.status==='missed' ? appointmentStatus==='upcoming' : '' )).length>0).map((data, i) => (
                    <div key={i} className="lists">

                        <div key={i} className="top-line">
                            <div className="date">
                                {data.date}
                            </div>
                            <div className="line"> </div>
                        </div>

                        <div className="list">
                            {data.appointments.filter((f)=>f.status===appointmentStatus || (f.status==='missed' ? appointmentStatus==='upcoming' : '' )).map((appointment, j) => (
                                <div key={j} className="card">
                                    <Link
                                        to={{
                                            pathname:'appointments/'+appointment.id,
                                            state: { appointment: appointment }
                                        }}
                                    >    
                                        <div className="top">
                                            <div className="id">{appointment.appointment_id}</div>
                                            <img alt="" />    
                                        </div>
                                        <div className="detail">
                                            <img src={appointment.patient.profile_pic} alt="" />
                                            <div className="name-age">
                                                <div className="name">{appointment.patient.name}</div>
                                                <div className="age">{appointment.patient.age}, {appointment.patient.gender}</div>
                                            </div>
                                        </div>
                                        <div className={ appointment.status === 'missed' ? "appointment-count missed-count" : "appointment-count"}>
                                            { appointment.status === 'missed' ? 'Missed' : appointment.appointment_str+' Appointment'}
                                        </div>
                                        <div className="appointment-date">
                                            {appointment.date} 
                                        </div>                                            
                                        <div className="time-duration">
                                            <div className="appointment-time"> {moment(appointment.start_time, 'hh:mm:ss').format('LT')} </div> 
                                            <div className="duration"> (15 MINS) </div>
                                        </div>
                                        <div className="star-icon-image">
                                            <img className="not-active-image" src={StarIcon} alt="" />
                                            <img className="active-image" src={StarIconActive} alt="" />
                                        </div>
                                        <div className="green-arrow">
                                            <i className="fa fa-arrow-right"></i>
                                        </div>
                                    </Link>    
                                </div>
                            ))}    
                        </div>
                    </div>
                ))
                : <div className="container center veritcal">No Data</div>
                }


            </section>  
        </div>
        <div className="error">{error}</div>
        </>
        }
        </SidebarComponent>
    )
}

export default Appointment
