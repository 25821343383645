import React from 'react'


const YoloInput = (props) => {
  const { className = '', fullWidth = false, gutters = 0 } = props
  if (props.options) {
    console.log('props.options')
    console.log(props.options)
  }
  return (

    props.options ?
      <select
        className={`input-base ${className}${fullWidth ? ' fw' : ''}`}
        style={gutters ? { marginBottom: `${gutters}px` } : {}}
        {...props}
      >
        {props.options.map(op =>
          <option value={op.id}>{op.name}</option>
        )}
      </select>
      : <input
        className={`input-base ${className}${fullWidth ? ' fw' : ''}`}
        style={gutters ? { marginBottom: `${gutters}px` } : {}}
        {...props}
      />
  )
}

export default YoloInput
