import React from 'react'
import { useFormContext } from '../utils/createContext';
export const medicalExaminationQuestionsData1 = [
  {
    "id":"1",
    "text": "Are you currently, or have you recently been taking medicines, pills or drugs? ",
    "options": ["YES", "NO"]
  },
  {
    "id":"2",
    "text": "Have you ever had or been suspected of having any of the following? ",
    "subQuestions": [
      {
        "id":"2.1",
        "text": "Cardio Vascular System (Fainting, shortness of breath, chest pain, palpitations, etc.)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2.2",
        "text": "Diabetes / Hypertension / Accident History / Endocrine Disorders (If you are diabetic and/or hypertensive, please fill Part 3)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2.3",
        "text": "Nervous System (Depression, insomnia, anxiety state, or mental illness, fits, blackouts, giddiness or migraine or other nervous disorder)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2.4",
        "text": "Urinary and Reproductive System (sugar, albumin, blood in urine, kidney stones, disorders of prostate / cervix, etc.)",
        "options": ["YES", "NO"]
      },
      {
        "id":"2.5",
        "text": "Anaemia, bleeding, or any blood disorders?",
        "options": ["YES", "NO"]
      },
      {
        "id":"2.6",
        "text": "Congenital Disorders",
        "options": ["YES", "NO"]
      },
      {
        "id": "2.7",
        "text": "Respiratory System (Bronchitis, asthma, pneumonia, emphysema, etc.)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2.8",
        "text": "Musculo Skeletal System and Collagen Disorders (Rheumatism, gout, backache, joint pains, rheumatoid arthritis, lupus, etc.)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2.9",
        "text": "Gastro-Intestinal System (Indigestion, ulcer, hernia, gallstones, hepatitis, colitis, etc.)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2.10",
        "text": "Any condition of eyes or vision (not wholly corrected by the specialist), please mention power of glasses if corrected",
        "options": ["YES", "NO"]
      },
      {
        "id":"2.11",
        "text": "Any affliction of the ears, hearing or balance?",
        "options": ["YES", "NO"]
      },
      {
        "id": "2.12",
        "text": "Cancer, tumor, cyst, or any abnormal growth",
        "options": ["YES", "NO"]
      },
      {
        "id": "2.13",
        "text": "Any surgical operation",
        "options": ["YES", "NO"]
      },
      {
        "id": "2.14",
        "text": "Any other ailment, disease, disorder or disturbance not covered above.",
        "options": ["YES", "NO"]
      }
    ]
  },
  {
    "id": "3",
    "text": "In the past, have you undergone any X-ray, ECG, Laboratory tests or other special investigation?",
    "options": ["YES", "NO"]
  },
  {
    "id": "4",
    "text": "Questions a-d: for female applicants only",
    
    "subQuestions": [
      {
        "id": "4.1",
        "text": "Have you ever suffered / are you suffering from gynecological problems?",
        "options": ["YES", "NO"]
      },
      {
        "id": "4.2",
        "text": "Are you pregnant now? If yes, give duration in weeks.",
        "options": ["YES", "NO"]
      },
      {
        "id": "4.3",
        "text": "Number of pregnancies, Date of last delivery:",
        "options": ["YES", "NO"]
      },
      {
        "id": "4.4",
        "text": "Any complications, miscarriage, medical termination of pregnancy or Caesarian?",
        "options": ["YES", "NO"]
      }
    ]
  },
  {
    "id": "5",
    "text": "Has there been any gain or loss in weight (in excess of 5 kgs) in the past 6 months?",
    "options": ["YES", "NO"]
  },
  {
    "id": "6",
    "text": "Do you use Tobacco in any form?",
    "options": ["YES", "NO"]
  },
  {
    "id": "7",
    "text": "Do you or have you ever taken alcohol, narcotics or drugs?",
    "options": ["YES", "NO"]
  },
  {
    "id": "8",
    "text": "Is there any family history of diabetes, heart disease, stroke, high blood pressure or nervous disorder, or are there any other hereditary disorders? ",
    "options": ["YES", "NO"]
  },
  {
    "id":"9",
    "text": "Have you consulted any doctor/medical examiner in the past three years for any treatment exceeding three days?",
    "options": ["YES", "NO"]
  },
  {
    "id": "10",
    "text": "Have you ever had a positive test for HIV/AIDS or suffered from malignancy of any type?",
    "options": ["YES", "NO"]
  },
  {
    "id": "11",
    "text": "Have you ever been tested or treated for other sexually transmitted diseases or Hepatitis B or C?",
    "options": ["YES", "NO"]
  },
  {
    "id": "12",
    "text": "Are you now in good health?",
    "options": ["YES", "NO"]
  }
]

export const medicalExaminationQuestionsData2 = [
  {
    "id": "13",
    "text": "Are there any symptoms and/or signs suggestive of abnormality of the following systems?",
    "subQuestions": [
      {
        "id": "13.1",
        "text": "Cardiovascular system (Is the heart normal in size and position? Are heart sounds normal? ",
        "options": ["YES", "NO"]
      },
      {
        "id": "13.2",
        "text": "Respiratory system (Is the chest well developed and does it expand well and equally?)",
        "options": ["YES", "NO"]
      },
      {
        "id": "13.3",
        "text": "Central or Peripheral nervous system (Are knee jerks and pupil responses normal?)",
        "options": ["YES", "NO"]
      },
      {
        "id": "13.4",
        "text": "Abdomen or Pelvis",
        "options": ["YES", "NO"]
      },
      {
        "id": "13.5",
        "text": "Musculoskeletal system (Are joint function and spinal movements normal?)",
        "options": ["YES", "NO"]
      },
      {
        "id": "13.6",
        "text": "Genitourinary system",
        "options": ["YES", "NO"]
      },
      {
        "id": "13.7",
        "text": "Is there any evidence of enlargement of liver or spleen?",
        "options": ["YES", "NO"]
      },
      {
        "id": "13.8",
        "text": "Is there any evidence of operation, accident, or injury?",
        "options": ["YES", "NO"],
      },
      {
        "id": "13.9",
        "text": "Any history of smoking and Alcohol Abuse?",
        "options": ["YES", "NO"]
      }
    ]
  },
  {
    "id": "14",
    "text": "For female applicants only:",
    "subQuestions": [
      {
        "id": "14.1",
        "text": "Is there any disease of the breasts?",
        "options": ["YES", "NO"]
      },
      {
        "id": "14.2",
        "text": "Do you suspect any disease of the uterus, cervix, or ovaries?",
        "options": ["YES", "NO"]
      },
      {
        "id":"14.3",
        "text": "Is there any evidence of pregnancy?",
        "options": ["YES", "NO"]
      }
    ]
  },
  {
    "id": "15",
    "text": "Is there any reason to suspect that the applicant is at risk of infection by the AIDS virus?",
    "options": ["YES", "NO"]
  },
  {
    "id": "16",
    "text": "On examination, does the applicant appear healthy?",
    "options": ["YES", "NO"]
  }
];
export const medicalExaminationQuestionsData4 = [
  {
    "id": 1,
    "text": "Are you currently, or have you recently been taking medicines, pills or drugs? ",
    "options": ["YES", "NO"]
  },
  {
    "id": 2,
    "text": "Have you ever had or been suspected of having any of the following? ",
    "options": ["YES", "NO"],
    "subQuestions": [
      {
        "id": "2a",
        "text": "Cardio Vascular System (Fainting, shortness of breath, chest pain, palpitations, etc.)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2b",
        "text": "Diabetes / Hypertension / Accident History / Endocrine Disorders (If you are diabetic and/or hypertensive, please fill Part 3)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2c",
        "text": "Nervous System (Depression, insomnia, anxiety state, or mental illness, fits, blackouts, giddiness or migraine or other nervous disorder)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2d",
        "text": "Urinary and Reproductive System (sugar, albumin, blood in urine, kidney stones, disorders of prostate / cervix, etc.)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2e",
        "text": "Anaemia, bleeding, or any blood disorders?",
        "options": ["YES", "NO"]
      },
      {
        "id": "2f",
        "text": "Congenital Disorders",
        "options": ["YES", "NO"]
      },
      {
        "id": "2g",
        "text": "Respiratory System (Bronchitis, asthma, pneumonia, emphysema, etc.)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2h",
        "text": "Musculo Skeletal System and Collagen Disorders (Rheumatism, gout, backache, joint pains, rheumatoid arthritis, lupus, etc.)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2i",
        "text": "Gastro-Intestinal System (Indigestion, ulcer, hernia, gallstones, hepatitis, colitis, etc.)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2j",
        "text": "Any condition of eyes or vision (not wholly corrected by the specialist), please mention power of glasses if corrected",
        "options": ["YES", "NO"]
      },
      {
        "id": "2k",
        "text": "Any affliction of the ears, hearing or balance?",
        "options": ["YES", "NO"]
      },
      {
        "id": "2l",
        "text": "Cancer, tumor, cyst, or any abnormal growth",
        "options": ["YES", "NO"]
      },
      {
        "id": "2m",
        "text": "Any surgical operation",
        "options": ["YES", "NO"]
      },
      {
        "id": "2n",
        "text": "Any other ailment, disease, disorder or disturbance not covered above.",
        "options": ["YES", "NO"]
      }
    ]
  },
  {
    "id": 3,
    "text": "In the past, have you undergone any X-ray, ECG, Laboratory tests or other special investigation?",
    "options": ["YES", "NO"]
  },
  {
    "id": 4,
    "text": "Questions a-d: for female applicants only",
    "subQuestions": [
      {
        "id": "4a",
        "text": "Have you ever suffered / are you suffering from gynecological problems? If yes, furnish details.",
        "options": ["YES", "NO"]
      },
      {
        "id": "4b",
        "text": "Are you pregnant now? If yes, give duration in weeks.",
        "options": ["YES", "NO"]
      },
      {
        "id": "4c",
        "text": "Number of pregnancies, Date of last delivery:",
        "options": ["YES", "NO"]
      },
      {
        "id": "4d",
        "text": "Any complications, miscarriage, medical termination of pregnancy or Caesarian? If yes, please furnish details.",
        "options": ["YES", "NO"]
      }
    ]
  },
  {
    "id": 5,
    "text": "Has there been any gain or loss in weight (in excess of 5 kgs) in the past 6 months?",
    "options": ["YES", "NO"]
  },
  {
    "id": 6,
    "text": "Do you use Tobacco in any form?",
    "options": ["YES", "NO"]
  },
  {
    "id": 7,
    "text": "Do you or have you ever taken alcohol, narcotics or drugs?",
    "options": ["YES", "NO"]
  },
  {
    "id": 8,
    "text": "Is there any family history of diabetes, heart disease, stroke, high blood pressure or nervous disorder, or are there any other hereditary disorders? If so, please give particulars.",
    "options": ["YES", "NO"]
  },
  {
    "id": 9,
    "text": "Have you consulted any doctor/medical examiner in the past three years for any treatment exceeding three days? If so, please give date(s) of consultation, reasons for consultation and diagnosis.",
    "options": ["YES", "NO"]
  },
  {
    "id": 10,
    "text": "Have you ever had a positive test for HIV/AIDS or suffered from malignancy of any type?",
    "options": ["YES", "NO"]
  },
  {
    "id": 11,
    "text": "Have you ever been tested or treated for other sexually transmitted diseases or Hepatitis B or C?",
    "options": ["YES", "NO"]
  },
  {
    "id": 12,
    "text": "Are you now in good health?",
    "options": ["YES", "NO"]
  }
]


export const medicalExaminationQuestionsData3 = [
  {
    "id": 17,
    "text": "Are you currently, or have you recently been taking medicines, pills or drugs? ",
    "options": ["YES", "NO"]
  },
  {
    "id": 18,
    "text": "Have you ever had or been suspected of having any of the following? ",
    "options": ["YES", "NO"],
    "subQuestions": [
      {
        "id": "2a",
        "text": "Cardio Vascular System (Fainting, shortness of breath, chest pain, palpitations, etc.)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2b",
        "text": "Diabetes / Hypertension / Accident History / Endocrine Disorders (If you are diabetic and/or hypertensive, please fill Part 3)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2c",
        "text": "Nervous System (Depression, insomnia, anxiety state, or mental illness, fits, blackouts, giddiness or migraine or other nervous disorder)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2d",
        "text": "Urinary and Reproductive System (sugar, albumin, blood in urine, kidney stones, disorders of prostate / cervix, etc.)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2e",
        "text": "Anaemia, bleeding, or any blood disorders?",
        "options": ["YES", "NO"]
      },
      {
        "id": "2f",
        "text": "Congenital Disorders",
        "options": ["YES", "NO"]
      },
      {
        "id": "2g",
        "text": "Respiratory System (Bronchitis, asthma, pneumonia, emphysema, etc.)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2h",
        "text": "Musculo Skeletal System and Collagen Disorders (Rheumatism, gout, backache, joint pains, rheumatoid arthritis, lupus, etc.)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2i",
        "text": "Gastro-Intestinal System (Indigestion, ulcer, hernia, gallstones, hepatitis, colitis, etc.)",
        "options": ["YES", "NO"]
      },
      {
        "id": "2j",
        "text": "Any condition of eyes or vision (not wholly corrected by the specialist), please mention power of glasses if corrected",
        "options": ["YES", "NO"]
      },
      {
        "id": "2k",
        "text": "Any affliction of the ears, hearing or balance?",
        "options": ["YES", "NO"]
      },
      {
        "id": "2l",
        "text": "Cancer, tumor, cyst, or any abnormal growth",
        "options": ["YES", "NO"]
      },
      {
        "id": "2m",
        "text": "Any surgical operation",
        "options": ["YES", "NO"]
      },
      {
        "id": "2n",
        "text": "Any other ailment, disease, disorder or disturbance not covered above.",
        "options": ["YES", "NO"]
      }
    ]
  },
  {
    "id": 3,
    "text": "In the past, have you undergone any X-ray, ECG, Laboratory tests or other special investigation?",
    "options": ["YES", "NO"]
  },
  {
    "id": 4,
    "text": "Questions a-d: for female applicants only",
    "subQuestions": [
      {
        "id": "4a",
        "text": "Have you ever suffered / are you suffering from gynecological problems? If yes, furnish details.",
        "options": ["YES", "NO"]
      },
      {
        "id": "4b",
        "text": "Are you pregnant now? If yes, give duration in weeks.",
        "options": ["YES", "NO"]
      },
      {
        "id": "4c",
        "text": "Number of pregnancies, Date of last delivery:",
        "options": ["YES", "NO"]
      },
      {
        "id": "4d",
        "text": "Any complications, miscarriage, medical termination of pregnancy or Caesarian? If yes, please furnish details.",
        "options": ["YES", "NO"]
      }
    ]
  },
  {
    "id": 5,
    "text": "Has there been any gain or loss in weight (in excess of 5 kgs) in the past 6 months?",
    "options": ["YES", "NO"]
  },
  {
    "id": 6,
    "text": "Do you use Tobacco in any form?",
    "options": ["YES", "NO"]
  },
  {
    "id": 7,
    "text": "Do you or have you ever taken alcohol, narcotics or drugs?",
    "options": ["YES", "NO"]
  },
  {
    "id": 8,
    "text": "Is there any family history of diabetes, heart disease, stroke, high blood pressure or nervous disorder, or are there any other hereditary disorders? If so, please give particulars.",
    "options": ["YES", "NO"]
  },
  {
    "id": 9,
    "text": "Have you consulted any doctor/medical examiner in the past three years for any treatment exceeding three days? If so, please give date(s) of consultation, reasons for consultation and diagnosis.",
    "options": ["YES", "NO"]
  },
  {
    "id": 10,
    "text": "Have you ever had a positive test for HIV/AIDS or suffered from malignancy of any type?",
    "options": ["YES", "NO"]
  },
  {
    "id": 11,
    "text": "Have you ever been tested or treated for other sexually transmitted diseases or Hepatitis B or C?",
    "options": ["YES", "NO"]
  },
  {
    "id": 12,
    "text": "Are you now in good health?",
    "options": ["YES", "NO"]
  }
]


const MedicalExaminationQuestion = ({ question, handleChange, parentQuestionId }) => {
  const { state } = useFormContext();

  // Function to get the current value for a question or sub-question
  const getValue = (id) => {
    return parentQuestionId ? state.answers[parentQuestionId]?.[id] : state.answers[id];
  };
  const generateRandomId = () => {
    return `id-${Math.random().toString(36).substr(2, 9)}-${Date.now()}`;
  };
  // Function to handle changes, considering nested sub-questions
  const onChange = (questionId, answer) => {
    if (parentQuestionId) {
      handleChange(parentQuestionId, { ...state.answers[parentQuestionId], [questionId]: answer });
    } else {
      handleChange(questionId, answer);
    }
  };
  const currentIdChain = generateRandomId() + question.id.toString();

  return (
    <div className="medical-question">
      <p>{question.id}) {question.text}</p>
      <div className='labels-container' style={{ marginTop: question.options ? 20 : 10 }}>
        {question.options?.map(option => (
          <label key={`${currentIdChain}-${option}`}>
            <input
              type="radio"
              name={question.id}
              value={option}
              checked={getValue(question.id) === option}
              onChange={() => onChange(question.id, option)}
            />
            {option}
          </label>
        ))}
      </div>
      <div>
        {question?.subQuestions?.map((subQuestion,index) => (
          <MedicalExaminationQuestion
          key={generateRandomId()}
            question={subQuestion}
            handleChange={handleChange}
            parentQuestionId={question.id}
          />
        ))}
      </div>
    </div>
  );
};




export default MedicalExaminationQuestion;


