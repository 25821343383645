import React, {useRef, useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom';
import { navbar } from '../utils/api';
import TimingModal from './TimingModal';
import ProfilePic from '../assets/images/profile-pic.png';
// import MessageSenderImg from '../assets/images/chat-notification-img.png'
import moment from 'moment';


const Loader = () => {
    return (
        <div className="ldsring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

  
const MyProfileModal = (props) => (
    <section className="my-profile-modal" ref={props.profileModalRef} >
        <Link to = {'/update-profile'} className = "edit-profile" >
            Edit Profile
        </Link>
        <Link className = "clininc-timing" onClick={props.openTimingModal}>
            Update Clinic Timings
        </Link>
        <Link className = "settings" onClick={props.openSettingModal}>
            Account settings
        </Link>
    </section>
)    

const ChatNotificationModal = (props) => {
    
    return (
        <section className="chat-notification-modal" ref={props.chatModalRef}>
            {props.navbarData.chat_notifications_new.length > 0 ?
            <>
            {props.navbarData.chat_notifications_new.map((data,i) => (
                <Link key = {i} to = {'/appointments/'+data.appointment_id} className = "chats">
                    <div className="image"><img src={data.patient_profile_pic} alt="" /></div>
                    <div className="content">
                        <div className="name">{data.name}</div>
                        <div className="msg"><span>{data.last_message}</span>. <b>{moment(data.msg_sent_time).format("MMM Do YYYY")}</b> </div>
                    </div>
                </Link>
            ))}   
            </>
            : <div className="no-notification">No new message</div>
            }
        </section>
    )
}

const SettingModal = (props) => {
    return (
        <section className="modal account-setting-modal" >
            <div className="setting-content">
                <div className="input-field">
                    <label className="label">Mobile</label>
                    <div className="input">{props.doctor_mobile}</div>
                </div>
                <div className="input-field">
                    <label className="label">E-mail</label>
                    <div className="input">{props.doctor_email}</div>
                </div>
            </div>
            <div className="green-button" onClick={props.closeModal}>Ok</div>
        </section>
    )
}

const Navbar = (props) => {  
    let location = useLocation().pathname;
    // console.log('sidebar console')
    // console.log(location)
    const [title, setTitle] = React.useState('Dashboard')
    useEffect(()=>{
        if(location==='/dashboard'){
            setTitle('Dashboard')
        }else if(location==='/calendar'){
            setTitle('Calendar')
        }else if(location==='/payments'){
            setTitle('Payments')
        }else if(location==='/update-profile'){
            setTitle('Update Profile Details')
        }else if(location.slice(0,13)==='/appointments'){
            setTitle('Appointments')
        }
    }, [location])

    const [initLoading, setInitLoading] = React.useState(false);
    const [myProfileModal, setMyProfileModal] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);
    const [showSettingModal, setShowSettingModal] = React.useState(false);
    const [chatNotificationModal, setChatNotificationModal] = React.useState(false);
    const [navbarData, setNavbarData] = React.useState({profile_pic: ProfilePic, chat_notifications: []});
    const [chatOutsideClickedRef, setChatOutsideClickedRef] = React.useState(false)
    const [profileOutsideClickedRef, setProfileOutsideClickedRef] = React.useState(false)
    
    function useChatOutsideAlerter(ref) {
        React.useEffect(() => {
            function handleClickOutside(event) {
                setChatOutsideClickedRef(ref.current)
                if (ref.current && !ref.current.contains(event.target)) {
                    setChatNotificationModal(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    
    function useProfileOutsideAlerter(ref) {
        React.useEffect(() => {
            function handleClickOutside(event) {
                setProfileOutsideClickedRef(ref.current)
                if (ref.current && !ref.current.contains(event.target)) {
                    setMyProfileModal(null)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const chatModalRef = useRef(null);
    useChatOutsideAlerter(chatModalRef);
    const profileModalRef = useRef(null);
    useProfileOutsideAlerter(profileModalRef);

    React.useEffect(() => {
        const callNavbarApi = () => {
            let navbarDataTemp = {}
            setInitLoading(true)
            navbar( (res)=>{
                console.log('navbar data')
                console.log(res)
                setInitLoading(false)
                if(res.data.status.result === 1){
                    navbarDataTemp = res.data.data
                    setNavbarData(navbarDataTemp)
                }else{
                    console.log(res.data.status.message)
                }
            })
        }
        callNavbarApi()
    }, []);

    const handleModalWrapperClick = (e) => {
        if(e.target !== e.currentTarget) return;
        setShowModal(false)
        setShowSettingModal(false)
      }
    

    return (
        <>
        <div className="topbar-wrapper">
            <div className="top-bar">
                <div className="heading">
                    {title}
                </div>
                <div className="profile">
                    { initLoading ?  <Loader/> :<>
                    <div className="chat-logo">
                        <img alt="" />
                    </div>
                    <div className="profile-image">
                        <i className="fa fa-comment" onClick={()=>setChatNotificationModal(chatOutsideClickedRef ? '' : !chatNotificationModal )} ></i>
                        <img className="circular-nav-image" src = {navbarData.profile_pic} onClick={()=>setMyProfileModal(profileOutsideClickedRef ? '' : !myProfileModal )} alt="" />
                        
                    </div>  
                    </>}
                </div>
                {
                    myProfileModal ?   
                    <MyProfileModal profileModalRef={profileModalRef} openSettingModal={()=>setShowSettingModal(true)} openTimingModal={()=>setShowModal(true)}/>
                    : ""
                } 
                {
                    chatNotificationModal ?   
                    <ChatNotificationModal chatModalRef={chatModalRef} navbarData={navbarData} />
                    : ""
                }    
            </div>
        </div>    
        { showModal ?
            <div className="modal-wrapper" onClick={handleModalWrapperClick}>
              <TimingModal closeModal={()=>setShowModal(false)} reload={props.reload}/>
            </div> : null 
        }
        { showSettingModal ?
            <div className="modal-wrapper" onClick={handleModalWrapperClick}>
              <SettingModal doctor_email={navbarData.email} doctor_mobile={navbarData.mobile} closeModal={()=>setShowSettingModal(false)} reload={props.reload}/>
            </div> : null 
        }
        </>
    )
}

export default Navbar
