import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Dashboard from './components/dashboard.js'
import Calendar from './components/Calendar.js'
import Home from './components/home.js'
import PrivateRoute from './components/PrivateRoute.js'
import Login from './components/login.js'
import Payment from './components/payment.js'
import UpdateProfile from './components/update-profile.js'
import Appointment from './components/appointments.js'
import AppointmentDetail from './components/appointments-detail.js'
import VideoChat from './components/VideoChat.js'
import QuickBlox from './components/QuickBloxComponent.js'
import './App.scss';
import { pdfjs } from "react-pdf";
import ViewPDF from './components/ViewPDF.js';
import {  ContextDataProvider } from './utils/createContext.js';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();
const App = () => {
  return (
    <ContextDataProvider>
    <Router>
        <QuickBlox />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/login/:id" component={Login} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/calendar" component={Calendar} />
          <PrivateRoute path="/payments" component={Payment} />
          <PrivateRoute path="/view-prescription-report" component={ViewPDF} />
          <PrivateRoute path="/update-profile" component={UpdateProfile} />
          <PrivateRoute exact path="/appointments" component={Appointment} />
          <PrivateRoute path="/appointments/:id" component={AppointmentDetail} />
          <PrivateRoute path="/video-chat" component={VideoChat}/>
        </Switch>
    </Router>
    </ContextDataProvider>
  );
}

export default App;
