import React, { useEffect } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import { get_daily_timings, set_daily_timings } from '../utils/api';
import 'react-calendar/dist/Calendar.css';

const TimePicker = ({ val, onChange }) => {
  let times = ['00:00 AM']
  let start = moment("00:00 AM", 'hh:mm A')
  for (var i = 0; i < 95; i++) {
    times.push(start.add(15, 'minutes').format('hh:mm A'))
  }

  return (
    <select onChange={onChange} className="time-picker">
      {times.map(time => <option selected={time === val} value={time}>{time}</option>)}
    </select>
  )
}

const TimingRow = ({ data, onDelete, setData, isInvalid }) => {
  const setStart = (e) => {
    setData({
      ...data,
      start: e.target.value
    })
  }
  const setEnd = (e) => {
    setData({
      ...data,
      end: e.target.value
    })
  }

  return (
    <div className={isInvalid ? 'timing-row-invalid' : 'timing-row-wrapper'} style={{ padding: '10px 0px' }}>
      <div className="text">
        From <TimePicker val={data.start} onChange={setStart} /> To <TimePicker val={data.end} onChange={setEnd} />
      </div>
      <div className="flex-filler"></div>
      <div className='circle-icon circle-cross' style={{ backgroundColor: 'white' }} onClick={onDelete}>
        <i className="fa fa-times"></i>
      </div>
    </div>
  );
}

const DailyTimingModal = ({ closeModal, reload }) => {

  const [timingData, setTimingData] = React.useState([])
  const [date, setDate] = React.useState(moment())
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState(null)

  useEffect(() => {
    const today = date.format("YYYY-MM-DD");
    get_daily_timings(today, (res) => {
      setLoading(false)
      setTimingData(res.data.data)
    })
  }, [date])

  const deleteRow = (i) => {
    setTimingData(timingData.filter((t, j) => j !== i))
  }

  const addRow = () => {
    setTimingData([
      ...timingData,
      {
        start: "11:00 AM",
        end: "1:00 PM",
      }
    ])
  }

  const handleTimingChange = (i, p) => {
    setTimingData(timingData.map((t, j) => j !== i ? t : p))
  }

  const handleDateChange = (d) => {
    setLoading(true)
    setDate(moment(d))
  }

  const handleSubmit = () => {
    const today = date.format("YYYY-MM-DD");
    set_daily_timings(today, timingData, () => {
      reload()
      // closeModal()
      setMessage(true)
    })
  }

  const isRowInValid = (i) => {
    const row = timingData[i];
    const rs = moment(row.start, "hh:mm a");
    const re = moment(row.end, "hh:mm a");
    for (var j = 0; j < i; j++) {
      var _row = timingData[j];
      var _s = moment(_row.start, "hh:mm a");
      var _e = moment(_row.end, "hh:mm a");
      if ((rs >= _s && rs <= _e) || (re >= _s && re <= _e)) {
        return true
      }
    }
    return false
  }

  const allRowsValid = () => {
    for (var i = 0; i < timingData.length; i++) {
      if (isRowInValid(i)) {
        return false;
      }
    }
    return true;
  }

  return (
    <div className={message ? "modal success-daily-timing-modal" : "modal daily-timing-modal"} >
      <i className="cross fa fa-times"
        onClick={closeModal}
      ></i>
      {message ? 'Your availability of time has been changed' :
        <div className="daily-timing-setting">
          <Calendar
            onChange={handleDateChange}
            value={date.toDate()}
          />
          {
            loading ? null :
              <div className="timing-form" style={{ flexGrow: 1, padding: '0px 40px 0px 65px' }}>
                <h3>Set available time for {date.format('MMMM Do YYYY')}</h3>
                {
                  timingData.map((t, i) => <TimingRow
                    data={t}
                    isInvalid={isRowInValid(i)}
                    onDelete={() => deleteRow(i)}
                    setData={p => { handleTimingChange(i, p) }} />)
                }
                <div style={{ display: 'flex', paddingTop: 20 }}>
                  <div className='flex-filler'></div>
                  <div class='circle-icon' onClick={addRow}>
                    <i className="fa fa-plus"></i>
                  </div>
                </div>
                <div style={{ display: 'flex', paddingTop: 20 }}>
                  <div className="flex-filler"></div>
                  {
                    allRowsValid() && <div class='green-button' onClick={handleSubmit}>
                      Confirm
                    </div>
                  }
                </div>
              </div>
          }
        </div>
      }
    </div>
  );
}

export default DailyTimingModal;