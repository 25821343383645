import React,{ useState } from "react";
import { Document, Page } from "react-pdf";
import logo from "../assets/images/logo_white.png"

function ViewPDF() {
  const [numPages, setNumPages] = useState();
  const [pageNumber] = useState(1);
  const searchParams = new URLSearchParams(window.location.search);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
let pdfUrl =searchParams.get('url');
  return (
     <div className="pdf-main-container">
        <header className="header">
        <img src={logo} width="200" height="auto" alt="logo" />
        </header>
   
    <Document file={pdfUrl}  onLoadSuccess={onDocumentLoadSuccess}>
    {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((page) => {
            return (
              <Page
                scale={1.5}
                pageNumber={page}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            );
          })}
    </Document>
    {numPages &&
    <p className="pdf-page-title">
        Total Pages {numPages}
      </p>
    }
  </div>
  );
}
export default ViewPDF;