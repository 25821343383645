import React, { useRef } from 'react'
import { uploadFile } from '../utils/api'
import Loader from './loader.js'
import ProfilePicture from '../assets/images/dashboard-dp.png';


const ProfilePic = (props) => {
    const [loading, setLoading] = React.useState(false);
    // const [uploaded, setUploaded] = React.useState(false);
    const [file, setFile] = React.useState(null);
    // let fileInput = React.createRef();
    const fileInput = useRef(null)

    const callFileUploadApi = (file) => {
        uploadFile(file).then((res) => {
            console.log(res);
            setLoading(false)
            // setUploaded(true)
            props.onFileUpload(res.data.data);
        }).catch((err) => {
            console.log(err);
            setLoading(false)
            // setUploaded(true)
            setFile(null)
        })
    }

    const handleClick = () => {
        fileInput.current.click();
    }

    const handleFileInputChange = (e) => {
        console.log(e.target.files[0])
        setLoading(true)
        setFile({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0]
          });
        callFileUploadApi(e.target.files[0])
    }

    return (
      <div>
        <div className='file-input-wrapper' >
          <label htmlFor="upload-button">
              <img 
                  src={file ? file.preview : (props.profilePicture ? props.profilePicture.doc : ProfilePicture)}
                  alt=""
              />
          </label>    
          <input
            type="file"
            id="upload-button"
            style={{ display: "none" }}
            onChange={handleFileInputChange}
            ref={fileInput}
         />
          <div className="filename" onClick={()=>handleClick()}>{file ? 'Add Profile Picture' : 'Change Profile Picture'}</div>
          {loading ? <Loader /> : ""}
          {/* {uploaded ? <i className="fa fa-check" aria-hidden="true"></i>: ""} */}
          {/* {props.link ? <a className="file-input-a"
            target="blank"
            onClick={e=>e.stopPropagation()}
            href={props.link}>current file</a> : null} */}
        </div>
      </div>
    );
}

export default ProfilePic;
