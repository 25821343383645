import React from 'react'
import { Redirect } from 'react-router-dom'
import { loginWithCode } from '../utils/api';
import { saveToken, saveValidityToken, saveQBDetails } from '../utils/auth';
import Loader from './loader';


const Login = (props) => {   
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState();
    const [isLoggedIn, setLoggedIn] = React.useState(false);
    const loginCode = props.match.params.id;

    React.useEffect(() => {
        const callLoginWithCodeApi = () => {
            setLoading(true)
            loginWithCode(loginCode, (res)=>{
                if(res.data.status.result === 1){
                    saveToken(res.data.data.token)
                    saveValidityToken(res.data.data.validity_token)
                    saveQBDetails(
                        res.data.data.qb_id,
                        res.data.data.qb_username,
                        res.data.data.qb_password
                    )
                    setLoading(false)
                    setLoggedIn(true)
                }else{
                    setLoading(false)
                    setError(res.data.status.message)
                }
                
            }, (err)=>{setLoading(false); setError(false)})
        }
        callLoginWithCodeApi();
    }, [loginCode]);

    return (
        isLoggedIn ? <Redirect to="/dashboard"/> :
        <div className="container center vertical">
            { loading ? <div className="container center"><Loader /></div> : "" }
            <div className="error">{error}</div>
        </div>
    )
}

export default Login
